import React from 'react';
import { stockCategoryDictionary } from 'common/stockCategoryDictionary';
import { stockTypeDictionary } from 'common/stockTypeDictionary';
import { ReservationChip } from './ReservationChip';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
import { Button } from 'components/button/Button.component';
import { WarningIcon } from 'components/warningIcon/WarningIcon.component';
import uuid from 'react-uuid';
import { useNewOfferFromStockApi } from 'pages/newOfferFromStock/useNewOfferFromStockApi';
import ModelDictionary from 'common/modelDictionary';
import { useFormattedCurrency } from 'utils/formatCurrency';
import { useStatus } from 'common/statusContext';
import { warningsDictionary } from 'pages/newOfferFromStock/warningsDictionary';
import { StockCarImage } from './StockCarImage.component';
export function StockCarsListBodyRow(_a) {
    var stockCar = _a.stockCar, selectedCar = _a.selectedCar, handleOnRowClick = _a.handleOnRowClick, _b = _a.searchingMode, searchingMode = _b === void 0 ? false : _b, handleSetAppError = _a.handleSetAppError;
    var newOfferFromStockApi = useNewOfferFromStockApi();
    var _c = useStatus(), status = _c.status, setStatus = _c.setStatus;
    var isSelected = selectedCar === stockCar.commonOrderNumber;
    function handleOnSelectStockCar() {
        var offerId = uuid();
        var fyon = stockCar.fyon;
        var _a = window.location.href.split('?'), searchParamsString = _a[1];
        var searchParams = new URLSearchParams(searchParamsString);
        var clientId = searchParams.get('clientId');
        var partyId = searchParams.has('partyId') ? searchParams.get('partyId') : null;
        var context = searchParams.get('context');
        var modelYear = stockCar.modelYear;
        var factoryOrderDate = stockCar.factoryOrderDate;
        var commonOrderNumber = stockCar.commonOrderNumber;
        var modelId = stockCar.modelId;
        var carOnLineDate = stockCar.carOnLineDate;
        var structureWeek = stockCar.structureWeek;
        var fixedPlannedDate = stockCar.fixedPlannedDate ? stockCar.fixedPlannedDate : null;
        setStatus('pending');
        newOfferFromStockApi.checkCar(offerId, fyon, clientId, partyId, modelYear, factoryOrderDate, commonOrderNumber, fixedPlannedDate)
            .then(function () {
            handleSetAppError(undefined);
            setStatus('success');
            // przejście do nowego kreatora
            var newSearchParams = new URLSearchParams();
            newSearchParams.set('offerIdFromStock', offerId);
            newSearchParams.set('context', context);
            newSearchParams.set('clientId', clientId);
            if (partyId) {
                newSearchParams.set('partyId', partyId);
            }
            window.location.hash = '#/creator?' + newSearchParams.toString();
        })
            .catch(function (error) {
            setStatus('error');
            if (error.response.data.errorCode === 'stock-car-not-exists') {
                // przejście do starego kreatora
                if (stockCar.oldCreatorAllowed) {
                    var oldCreatorSearchParams = new URLSearchParams();
                    var modelIdWithModelYear = modelId.includes('_') ? modelId : "".concat(modelId, "_").concat(modelYear);
                    oldCreatorSearchParams.set('modelId', modelIdWithModelYear);
                    oldCreatorSearchParams.set('commonOrderNumber', commonOrderNumber);
                    oldCreatorSearchParams.set('carOnLineDate', carOnLineDate);
                    oldCreatorSearchParams.set('structureWeek', structureWeek);
                    oldCreatorSearchParams.set('context', context);
                    oldCreatorSearchParams.set('clientId', clientId);
                    if (partyId) {
                        oldCreatorSearchParams.set('partyId', partyId);
                    }
                    window.location.href = '#/offer-wizard?' + oldCreatorSearchParams.toString();
                }
                else {
                    handleSetAppError({
                        redirect: 'errorPopup',
                        errorId: error.response.data.errorId ? error.response.data.errorId : '',
                        errorDesc: 'Brak zapisanej konfiguracji w systemie. Utwórz ofertę samodzielnie.',
                    });
                }
            }
            else {
                handleSetAppError({
                    redirect: 'errorPopup',
                    errorId: error.response.data.errorId ? error.response.data.errorId : '',
                    errorDesc: 'Nie można otworzyć wskazanej konfiguracji.',
                    retryHandler: handleOnSelectStockCar
                });
            }
        });
    }
    var currencyAmount = useFormattedCurrency(stockCar.listPrice ? stockCar.listPrice : 0);
    var filteredWarnings = stockCar.warnings && stockCar.warnings.length > 0 ? stockCar.warnings.filter(function (warning) { return warning !== ''; }) : [];
    var isSomeConficurationElement = stockCar.engine || stockCar.version || stockCar.upholstery || stockCar.color;
    return (React.createElement("div", { "data-testid": 'stock-car-element', className: " ".concat(isSelected && !searchingMode ? 'selectedStockCarListBodyWrapper' : 'stockCarsListBodyRowWrapper'), onClick: handleOnRowClick },
        React.createElement("div", { className: 'stockCarBaseData' },
            React.createElement("div", { className: 'stockCarListColumn stockCarListRadioColumn' }, searchingMode ?
                React.createElement(RadioGroup.Placeholder, null)
                : React.createElement(RadioGroup.Item, { value: stockCar.commonOrderNumber, id: stockCar.commonOrderNumber },
                    React.createElement(RadioGroup.Indicator, null))),
            React.createElement("div", { className: 'stockCarListColumn stockCarBaseColumn' },
                stockCar.reservation ? React.createElement("div", null,
                    React.createElement(ReservationChip, null)) : null,
                React.createElement("div", null,
                    React.createElement("p", { "data-testid": 'car-stock-category' }, stockCar.stockCategory ? stockCategoryDictionary(stockCar.stockCategory) : '-'),
                    React.createElement("p", { "data-testid": 'car-model' },
                        stockCar.modelId ? ModelDictionary.nameFor(stockCar.modelId) : '-',
                        " (",
                        stockCar.modelYear ? stockCar.modelYear : '-',
                        ")"),
                    React.createElement("p", { "data-testid": 'car-list-price' }, stockCar.listPrice ? currencyAmount : '-'))),
            React.createElement("div", { className: 'stockCarListColumn' },
                React.createElement("p", { "data-testid": 'car-stock-type' }, stockCar.type ? stockTypeDictionary(stockCar.type) : '-'),
                React.createElement("p", { "data-testid": 'car-dealer-order' }, stockCar.dealerOrder ? stockCar.dealerOrder : '-'),
                React.createElement("p", { "data-testid": 'car-vin' }, stockCar.vin ? stockCar.vin : '-')),
            React.createElement("div", { className: 'stockCarListColumn' },
                React.createElement("p", { "data-testid": 'car-deliver-date' }, stockCar.deliveryDate ? stockCar.deliveryDate : '-')),
            React.createElement("div", { className: 'stockCarListColumn' },
                React.createElement("p", { "data-testid": 'car-last-date-for-order-chage' }, stockCar.lastDateForOrderChange ? stockCar.lastDateForOrderChange : '-')),
            React.createElement("div", { className: 'stockCarConfigurationColumn stockCarListColumn' },
                React.createElement("div", { "data-testid": 'car-configuration-list-wrapper' }, isSomeConficurationElement ?
                    React.createElement("ul", { className: 'stockCarConfigurationList' },
                        React.createElement("li", null,
                            React.createElement("p", { className: 'stockConfigurationEngine' }, stockCar.engine ? stockCar.engine : '-')),
                        React.createElement("li", null,
                            React.createElement("p", { className: 'stockConfigurationVersion' }, stockCar.version ? stockCar.version : '-')),
                        React.createElement("li", null,
                            React.createElement("p", { className: 'stockConfigurationUpholstery' }, stockCar.upholstery ? stockCar.upholstery : '-')),
                        React.createElement("li", null,
                            React.createElement("p", { className: 'stockConfigurationColor' }, stockCar.color ? stockCar.color : '-')))
                    : React.createElement("p", { className: 'stockCarConfigurationList' }, "-")),
                React.createElement(StockCarImage, { stockCar: stockCar }))),
        isSelected ?
            React.createElement("div", { className: 'stockCarDetailedData' },
                React.createElement("div", { className: 'stockCarDetails' },
                    React.createElement("div", { className: 'stockCarDetailsRow' },
                        React.createElement("p", { className: 'stockCarDetailsLabel' }, "Dealer:"),
                        React.createElement("p", { "data-testid": 'car-organization-name' }, stockCar.organizationName ? stockCar.organizationName : '-')),
                    React.createElement("div", { className: 'stockCarDetailsRow' },
                        React.createElement("p", { className: 'stockCarDetailsLabel' }, "Data produkcji:"),
                        React.createElement("p", { "data-testid": 'car-production-date' }, stockCar.productionDate ? stockCar.productionDate : '-')),
                    React.createElement("div", { className: 'stockCarDetailsRow' },
                        React.createElement("p", { className: 'stockCarDetailsLabel' }, "Nap\u0119d:"),
                        React.createElement("p", { "data-testid": 'car-engine-description' }, stockCar.engineDescription ? stockCar.engineDescription : '-')),
                    React.createElement("div", { className: 'stockCarDetailsRow' },
                        React.createElement("p", { className: 'stockCarDetailsLabel' }, "Numer rejestracyjny:"),
                        React.createElement("p", { "data-testid": 'car-registration-number' }, stockCar.registrationNumber ? stockCar.registrationNumber : '-'))),
                stockCar.comment ?
                    React.createElement("div", { className: 'stockCarDetailsCommentWrapper' },
                        React.createElement("p", { className: 'stockCarDetailsLabel' }, "Uwagi do konfiguracji:"),
                        React.createElement("p", { style: { whiteSpace: 'break-spaces' }, "data-testid": 'car-comment' }, stockCar.comment ? stockCar.comment : '-'))
                    : null,
                filteredWarnings && filteredWarnings.length > 0 ?
                    React.createElement("div", { className: 'stockCarDetailsWarningsWrapper' },
                        React.createElement("div", { className: 'stockCarDetailsWarnings' },
                            React.createElement("div", { className: 'stockCarWarningIcon' },
                                React.createElement(WarningIcon, null)),
                            React.createElement("div", { className: 'stockCarDetailsWarningsList', "data-testid": 'car-warnings-wrapper' }, filteredWarnings.map(function (warning, index) { return (React.createElement("p", { key: index }, warningsDictionary(warning))); }))))
                    : null,
                React.createElement("div", { className: 'stockCarDetailsButtonWrapper' },
                    React.createElement("div", { className: 'stockCarDetilsButton' },
                        React.createElement(Button, { disabled: status === 'pending', onClick: handleOnSelectStockCar }, "wybierz"))))
            : null));
}
