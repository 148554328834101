import React from 'react';
import './supplementSection.css';
import ModelDictionary from 'common/modelDictionary';
import '../contractSection/contractSection.css';
import { fundingFilter } from 'common/fundingFilter';
import { vcpDiscountName } from 'common/vcpDiscountName';
import { List } from 'components/list/List.component';
import { useConcessionApi } from '../useConcessionApi';
import { openFile } from 'common/openFile';
import { Button } from 'components/button/Button.component';
export function SupplementSectionPreview(_a) {
    var supplementSectionData = _a.supplementSectionData, isVCPSales = _a.isVCPSales, concessionId = _a.concessionId;
    var supplements = supplementSectionData.supplements;
    var concessionApi = useConcessionApi();
    function handleFileNameClick(fileUrl, fileName) {
        concessionApi.loadLocation(fileUrl).then(function (response) { return openFile(response, fileName); }).catch(function () { });
        ;
    }
    function onSupplementApplicationAcceptanceClick(supplementId) {
        window.location.href = "#/concessions/supplement-acceptance/".concat(concessionId, "/").concat(supplementId, "?fromNewSearch");
    }
    return (supplements ?
        React.createElement("div", { className: 'supplementSectionWrapper supplementsContainer', "data-testid": "supplement-application-section-wrapper" }, supplements.map(function (supplement, index) { return (React.createElement("div", { key: index, className: 'supplementContainer' },
            React.createElement("div", null,
                supplement.order.length > 0 &&
                    React.createElement("div", { className: 'supplementSection' },
                        React.createElement("div", { className: 'supplementSectionLabel' }, "Dodatkowe zam\u00F3wienie:"),
                        React.createElement("div", { className: 'contractSectionCarsTableWrapper', "data-testid": "supplement-section-cars-table-wrapper" },
                            React.createElement("table", { className: "contractSectionCarsTable" },
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement("th", { className: 'contractSectionCarTableHeader contractSectionModelCell' }, "Model Volvo"),
                                        React.createElement("th", { className: 'contractSectionCarTableHeader contractSectionAmountCell' }, "Ilo\u015B\u0107"))),
                                React.createElement("tbody", null, supplement.order.map(function (order, index) { return (React.createElement("tr", { key: index, className: 'contractSectionCarTableRow' },
                                    React.createElement("td", { className: 'contractSectionCarTableCell contractSectionModelCell' }, ModelDictionary.nameFor(order.model.split('_')[0])),
                                    React.createElement("td", { className: 'contractSectionCarTableCell contractSectionAmountCell' }, order.quantity))); }))))),
                supplement.funding &&
                    React.createElement("div", { className: 'supplementSection' },
                        React.createElement("div", { className: 'supplementSectionLabel' }, "Zmiana rodzaju finansowania:"),
                        React.createElement("div", { className: 'supplementSectionValue' }, fundingFilter(supplement.funding))),
                supplement.discount.discountGroup.id &&
                    React.createElement("div", { className: 'supplementSection' },
                        React.createElement("div", { className: 'supplementSectionLabel' }, "Zmiana Oferty Specjalnej / Grupy Rabatowej VCP:"),
                        React.createElement("div", { className: 'supplementSectionValue' }, vcpDiscountName(supplement.discount))),
                supplement.discountGroupQualificationEvidences.length > 0 &&
                    React.createElement("div", { className: 'supplementSection' },
                        React.createElement("div", { className: 'supplementSectionLabel' }, "Za\u0142\u0105czone dokumenty:"),
                        React.createElement("div", null,
                            React.createElement(List.Root, null, supplement.discountGroupQualificationEvidences.map(function (file) { return (React.createElement(List.Item, { key: file.location },
                                React.createElement("div", { className: "vcpDiscountSectionAttachmentItem" },
                                    React.createElement("div", { onClick: function () { return handleFileNameClick(file.location, file.fileName); }, "data-testid": "ordering-party-section-evidences-attachment" }, file.fileName)))); })))),
                supplement.validTo &&
                    React.createElement("div", { className: 'supplementSection' },
                        React.createElement("div", { className: 'supplementSectionLabel' }, "Wyd\u0142u\u017Cenie terminu wa\u017Cno\u015Bci:"),
                        React.createElement("div", { className: 'supplementSectionValue' },
                            "do ",
                            supplement.validTo))),
            React.createElement("div", { className: 'supplementSection' },
                React.createElement("div", { className: 'supplementSectionLabel' }, "Komentarz:"),
                React.createElement("div", { className: 'supplementSectionValue' }, supplement.comment)),
            isVCPSales &&
                React.createElement(React.Fragment, null,
                    React.createElement("div", null),
                    React.createElement("div", { className: 'supplementSectionAcceptanceButton' },
                        React.createElement(Button, { variant: 'secondary', type: "button", onClick: function () { return onSupplementApplicationAcceptanceClick(supplement.id); } }, "przejd\u017A do akceptacji"))))); }))
        : null);
}
