var ModelDictionary = /** @class */ (function () {
    function ModelDictionary() {
        this.models = {
            '124': { name: 'S80', includedOnPriceList: true },
            '134': { name: 'S60', includedOnPriceList: true },
            '135': { name: 'V70', includedOnPriceList: true },
            '136': { name: 'XC70', includedOnPriceList: true },
            '137': { name: 'S60 Cross Country', includedOnPriceList: true },
            '155': { name: 'V60', includedOnPriceList: true },
            '156': { name: 'XC60', includedOnPriceList: true },
            '157': { name: 'V60 Cross Country', includedOnPriceList: true },
            '224': { name: 'S60', includedOnPriceList: true },
            '225': { name: 'V60', includedOnPriceList: true },
            '227': { name: 'V60 Cross Country', includedOnPriceList: true },
            '234': { name: 'S90', includedOnPriceList: true },
            '235': { name: 'V90', includedOnPriceList: true },
            '236': { name: 'V90 Cross Country', includedOnPriceList: true },
            '244': { name: '240', includedOnPriceList: false },
            '246': { name: 'XC60', includedOnPriceList: true },
            '256': { name: 'XC90', includedOnPriceList: true },
            '275': { name: 'XC90', includedOnPriceList: false },
            '384': { name: 'S60', includedOnPriceList: false },
            '525': { name: 'V40', includedOnPriceList: true },
            '526': { name: 'V40 Cross Country', includedOnPriceList: true },
            '533': { name: 'C30', includedOnPriceList: false },
            '536': { name: 'XC40', includedOnPriceList: true },
            '536BEV': { name: 'EX40', includedOnPriceList: true },
            '539': { name: 'EC40', includedOnPriceList: true },
            '356': { name: 'EX90', includedOnPriceList: true },
            '416': { name: 'EX30', includedOnPriceList: true },
            '417': { name: 'EX30 Cross Country', includedOnPriceList: true },
            '334': { name: 'ES90', includedOnPriceList: true },
            '542': { name: 'C70', includedOnPriceList: false },
            '544': { name: 'S40', includedOnPriceList: false },
            '545': { name: 'V50', includedOnPriceList: false },
            '644': { name: 'S40', includedOnPriceList: false },
            '645': { name: 'V40', includedOnPriceList: false },
            '764': { name: '760', includedOnPriceList: false },
            '782': { name: '780', includedOnPriceList: false },
            '854': { name: '850', includedOnPriceList: false },
            '872': { name: 'S70/C70', includedOnPriceList: false },
            '945': { name: '940', includedOnPriceList: false },
            '964': { name: '960', includedOnPriceList: false },
        };
    }
    ModelDictionary.prototype.nameFor = function (modelCode) {
        return this.models[modelCode] ? this.models[modelCode].name : modelCode;
    };
    ModelDictionary.prototype.list = function () {
        return this.models.map(function (model) { return model.name; });
    };
    ModelDictionary.prototype.includedOnPriceList = function () {
        return this.models
            .filter(function (model) {
            return model.includedOnPriceList;
        })
            .map(function (model) {
            return model.name;
        });
    };
    return ModelDictionary;
}());
export default new ModelDictionary();
