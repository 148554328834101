var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useEffect, useState } from 'react';
import { useTradeInApi } from '../useTradeInApi';
import ReactDOM from 'react-dom';
import { useStatus } from 'common/statusContext';
export function useOrderingPartySection() {
    var _a = useState(), parties = _a[0], setParties = _a[1];
    var _b = useState(), party = _b[0], setParty = _b[1];
    var _c = useState(), partyCars = _c[0], setPartyCars = _c[1];
    var _d = useState(), selectedCar = _d[0], setSelectedCar = _d[1];
    var _e = window.location.href.split('?'), searchParamsString = _e[1];
    var searchParams = new URLSearchParams(searchParamsString);
    var isFromPartyCard = searchParams.has('partyId');
    var setStatus = useStatus().setStatus;
    var tradeInApi = useTradeInApi();
    function getOrders(partyId) {
        return __awaiter(this, void 0, void 0, function () {
            var ordersResponse, partyCarsWithOrder;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, tradeInApi.getOrdersByParty(partyId)];
                    case 1:
                        ordersResponse = _a.sent();
                        partyCarsWithOrder = ordersResponse.filter(function (order) { return order.dealerOrder; });
                        setPartyCars(partyCarsWithOrder);
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        function getParties(clientId) {
            return __awaiter(this, void 0, void 0, function () {
                var partiesResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, tradeInApi.getPartiesWithIdentyficationNumber(clientId)];
                        case 1:
                            partiesResponse = _a.sent();
                            setParties(partiesResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getParty(partyId) {
            return __awaiter(this, void 0, void 0, function () {
                var partyResponse, party;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, tradeInApi.getParty(encodeURIComponent(partyId))];
                        case 1:
                            partyResponse = _a.sent();
                            party = {
                                id: partyResponse.id,
                                type: partyResponse.type,
                                name: partyResponse.name,
                                identificationNumber: {
                                    type: partyResponse.identificationNumber.type,
                                    value: partyResponse.identificationNumber.value
                                },
                                address: __assign({}, partyResponse.address)
                            };
                            ReactDOM.unstable_batchedUpdates(function () {
                                setParty(party);
                                setStatus('success');
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (searchParams.has('clientId')) {
            var clientId = searchParams.get('clientId');
            getParties(clientId);
        }
        if (searchParams.has('partyId')) {
            var partyId = searchParams.get('partyId');
            getParty(partyId);
            getOrders(encodeURIComponent(partyId));
        }
    }, []);
    function handleSelectedPartyChange(value) {
        ReactDOM.unstable_batchedUpdates(function () {
            var selectedParty = parties.find(function (party) { return party.id === value; });
            setParty(selectedParty);
            getOrders(encodeURIComponent(selectedParty.id));
            if (selectedCar) {
                setSelectedCar(null);
            }
        });
    }
    function onCarChange(option) {
        setSelectedCar(option);
    }
    return {
        parties: parties,
        party: party,
        isFromPartyCard: isFromPartyCard,
        handleSelectedPartyChange: handleSelectedPartyChange,
        onCarChange: onCarChange,
        selectedCar: selectedCar,
        isRequiredDataFilled: !!party && !!selectedCar,
        partyCars: partyCars
    };
}
