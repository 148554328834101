export function OldAuditDocumentationConfig() {
    return [
        {
            key: 'FINAL_INVOICE',
            label: 'Finalna faktura wraz z korektami wystawiona na Klienta z numerem ' +
                'rejestracyjnym (w\u00A0przypadku, gdy faktura wystawiona jest na firmę finansującą wymagany jest także protokół ' +
                'przekazania Klientowi finalnemu, np. po upoważnieniu od leasing / CFM do wydania pojazdu klientowi)'
        },
        {
            key: 'ORDER_CONFIRMATION',
            label: 'Potwierdzenie zamówienia Klienta na doposażenie pojazdu w akcesoria montowane na miejscu (wraz z ceną doposażenia) ' +
                'podpisane przez Klienta'
        },
        {
            key: 'SALE_AGREEMENT',
            label: 'Umowa – zamówienie lub inna umowa mająca za przedmiot sprzedaż samochodu'
        },
        {
            key: 'VCP_INVOICE',
            label: 'Faktura wystawiona przez VCP na Dealera'
        },
        {
            key: 'REGISTRATION_LICENSE',
            label: 'Kopia dowodu rejestracyjnego – stałego'
        },
        {
            key: 'INSURANCE_POLICY',
            label: 'Kopia Polisy Ubezpieczeniowej'
        },
        {
            key: 'PAYMENT_CONFIRMATION',
            label: 'Potwierdzenie wpłynięcia środków za dany pojazd'
        },
        {
            key: 'HANDOVER_CONFIRMATION',
            label: 'Potwierdzenie wydania samochodu Klientowi'
        },
        {
            key: 'DISCOUNT_POLICY_ATTACHMENT',
            label: 'Podpisany załącznik nr. 3 do Polityki Flotowej - w przypadku firm RAC i, na wniosek VCP, w przypadku Ofert Specjalnych'
        },
        {
            key: 'OTHER',
            label: 'Inne'
        },
        {
            key: 'SUPPLEMENTS',
            label: 'Uzupełnienie braków audytowych'
        }
    ];
}
