import React from 'react';
import './vcpDiscountSection.css';
import { selectedVcpDiscount } from 'common/selectedVcpDiscount';
import { List } from 'components/list/List.component';
import { useConcessionApi } from '../useConcessionApi';
import { openFile } from 'common/openFile';
import { ChangesHistory } from '../changesHistory/ChangesHistory';
export function VcpDiscountPreviewSection(_a) {
    var vcpDiscountSectionData = _a.vcpDiscountSectionData;
    var concessionApi = useConcessionApi();
    function handleFileNameClick(fileUrl, fileName) {
        concessionApi.loadLocation(fileUrl).then(function (response) { return openFile(response, fileName); }).catch(function () { });
        ;
    }
    return (vcpDiscountSectionData &&
        React.createElement("div", { className: 'vcpDiscountSectionWrapper' },
            React.createElement("div", { className: 'vcpDiscountSectionGridWrapper' },
                React.createElement("div", { className: 'vcpDiscountSectionGridColumn' },
                    React.createElement("div", null,
                        React.createElement("div", { className: "vcpDiscountLabel" }, "Termin wa\u017Cno\u015Bci:"),
                        React.createElement("div", { className: "vcpDiscountSectionValue", "data-testid": 'vcp-discount-section-validity' },
                            "od ",
                            vcpDiscountSectionData.validFrom,
                            " do ",
                            vcpDiscountSectionData.validTo)),
                    React.createElement("div", null,
                        React.createElement("div", { className: "vcpDiscountLabel" }, "Oferta specjalna / Grupa rabatowa VCP:"),
                        React.createElement("div", { className: "vcpDiscountSectionValue", "data-testid": 'vcp-discount-section-discount-group' }, selectedVcpDiscount(vcpDiscountSectionData.discount)))),
                React.createElement("div", { className: 'vcpDiscountSectionGridColumn' },
                    React.createElement("div", null,
                        React.createElement("div", { className: "vcpDiscountLabel" }, "Za\u0142\u0105czniki:"),
                        React.createElement("div", null, vcpDiscountSectionData.discountGroupQualificationEvidences.length > 0 ? (React.createElement(List.Root, null, vcpDiscountSectionData.discountGroupQualificationEvidences.map(function (file) { return (React.createElement(List.Item, { key: file.location },
                            React.createElement("div", { className: "vcpDiscountSectionAttachmentItem" },
                                React.createElement("div", { onClick: function () { return handleFileNameClick(file.location, file.fileName); }, "data-testid": "vcp-discount-section-evidences-attachment" }, file.fileName)))); }))) : null)))),
            React.createElement("div", null, (vcpDiscountSectionData === null || vcpDiscountSectionData === void 0 ? void 0 : vcpDiscountSectionData.changes) &&
                React.createElement(ChangesHistory, { changesHistory: vcpDiscountSectionData.changes }))));
}
