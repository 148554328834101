import { CarPreviewImage } from 'components/carPreviewImage/CarPreviewImage.component';
import { Dialog } from 'components/dialog/Dialog.component';
import React from 'react';
import '../../newOfferFromStock.css';
export function StockCarImage(_a) {
    var stockCar = _a.stockCar;
    function getAvailableImage() {
        if (stockCar && stockCar.images) {
            var exteriorThreeQuartersFrontLeftImage = stockCar.images.EXTERIOR_threeQuartersFrontLeft;
            if (exteriorThreeQuartersFrontLeftImage) {
                return stockCar.images.EXTERIOR_threeQuartersFrontLeft;
            }
            else {
                var exteriorImagesKeys = Object.keys(stockCar.images).filter(function (key) { return key.startsWith('EXTERIOR'); });
                if (exteriorImagesKeys && exteriorImagesKeys.length > 0) {
                    var otherExteriorImage = stockCar.images[exteriorImagesKeys[0]];
                    return otherExteriorImage;
                }
            }
        }
        return false;
    }
    return (React.createElement(Dialog.Root, null,
        React.createElement(Dialog.Trigger, { cursorPointer: getAvailableImage() },
            React.createElement("div", { className: 'stockCarThumbnail' }, getAvailableImage() && React.createElement("img", { alt: 'stock car thumbnail', className: 'stockCarThumbnailImg', src: getAvailableImage() }))),
        getAvailableImage() &&
            React.createElement(Dialog.Content, null,
                React.createElement(Dialog.CloseButton, null),
                React.createElement("div", { className: 'carPreviewImageWrapper' },
                    React.createElement(CarPreviewImage, { src: getAvailableImage() })))));
}
