import React from 'react';
import './orderingPartySection.css';
import { Dropdown } from 'components/dropdown/Dropdown.component';
import { clientPartyTypeDescription } from 'common/clientPartyTypeDescription';
import ModelDictionary from 'common/modelDictionary';
var generateTradeInCarOptionLabel = function (e) {
    var _a = e.modelId.split('_'), modelId = _a[0], modelYear = _a[1];
    return (ModelDictionary.nameFor(modelId) +
        ' ' +
        "(".concat(modelYear, ")") +
        ', ' +
        "".concat(e.dealerOrder ? 'DO: ' + e.dealerOrder + ',' : '') +
        ' ' +
        "".concat(e.commonOrderNumber ? 'CON: ' + e.commonOrderNumber : '') +
        ' ' +
        "".concat(e.vin ? ', ' + e.vin : '') +
        ' ' +
        "".concat(e.registrationNumber ? ', ' + e.registrationNumber : ''));
};
export function OrderingPartySection(_a) {
    var orderingPartySectionData = _a.orderingPartySectionData;
    var parties = orderingPartySectionData.parties, party = orderingPartySectionData.party, handleSelectedPartyChange = orderingPartySectionData.handleSelectedPartyChange, isFromPartyCard = orderingPartySectionData.isFromPartyCard, onCarChange = orderingPartySectionData.onCarChange, selectedCar = orderingPartySectionData.selectedCar, partyCars = orderingPartySectionData.partyCars;
    var partiesDropdownOptions = parties ? parties.map(function (party) {
        return {
            value: party.id,
            label: "".concat(clientPartyTypeDescription(party.type), " ").concat(party.name, ", ").concat(party.identificationNumber.type, ": ").concat(party.identificationNumber.value),
        };
    })
        : [];
    var carsDropdrownOptions = partyCars ? partyCars.map(function (car) {
        return {
            value: car.id,
            label: generateTradeInCarOptionLabel(car)
        };
    })
        : [];
    return (React.createElement("div", { className: 'orderingPartySectionWrapper' },
        React.createElement("div", { className: 'orderingPartySectionDropdownsContainer' },
            React.createElement("div", { className: 'orderingPartyDropdown' },
                React.createElement("div", { className: "orderingPartyLabel" }, "Strona klienta:"),
                !isFromPartyCard ? (React.createElement("div", { className: "orderingPartyDropdown", "data-testid": "ordering-party-section-party-dropdown" },
                    React.createElement(Dropdown, { options: partiesDropdownOptions, name: "orderingParty", onChange: handleSelectedPartyChange, placeholder: "Wybierz stron\u0119*", noOptionsMessage: "Brak stron" }))) : party ? (React.createElement("div", { className: "orderingPartySectionValue", "data-testid": "ordering-party-section-party-value" },
                    party.name,
                    " ",
                    party.identificationNumber.type,
                    ": ",
                    party.identificationNumber.value)) : null),
            React.createElement("div", { className: 'orderingPartyDropdown' },
                React.createElement("div", { className: "orderingPartyLabel" }, "Samoch\u00F3d:"),
                React.createElement("div", { className: "stockCarSearch" },
                    React.createElement(Dropdown, { options: carsDropdrownOptions, name: 'orderingCar', onChange: onCarChange, placeholder: 'Wybierz samochód*', noOptionsMessage: 'Brak samochod\u00F3w', disabled: !party, value: selectedCar ? partyCars.find(function (partyCar) { return partyCar.id === selectedCar.id; }) : null }))))));
}
