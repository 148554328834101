import React from 'react';
import './contractSection.css';
import { fundingFilter } from 'common/fundingFilter';
import ModelDictionary from 'common/modelDictionary';
export function ContractSection(_a) {
    var contractPreviewData = _a.contractPreviewData;
    return (contractPreviewData &&
        React.createElement("div", { className: 'contractSectionWrapper', "data-testid": 'contract-section' },
            React.createElement("div", { className: 'contractSectionColumnsWrapper' },
                React.createElement("div", null,
                    React.createElement("div", { className: 'contractSectionLabel' }, "Rodzaj finansowania:"),
                    React.createElement("div", { className: 'contractSectionValue', "data-testid": 'contract-section-funding-value' }, fundingFilter(contractPreviewData.funding))),
                React.createElement("div", null,
                    React.createElement("div", { className: 'contractSectionLabel' }, "Zam\u00F3wienie:"),
                    React.createElement("div", { className: 'contractSectionCarsTableWrapper', "data-testid": 'contract-section-cars-table-wrapper' },
                        React.createElement("table", { className: "contractSectionCarsTable" },
                            React.createElement("thead", null,
                                React.createElement("tr", null,
                                    React.createElement("th", { className: 'contractSectionCarTableHeader contractSectionModelCell' }, "Model Volvo"),
                                    React.createElement("th", { className: 'contractSectionCarTableHeader contractSectionAmountCell' }, "Ilo\u015B\u0107"))),
                            React.createElement("tbody", null, contractPreviewData.order && contractPreviewData.order.length > 0 ?
                                contractPreviewData.order.map(function (order, index) { return (React.createElement("tr", { key: index, className: 'contractSectionCarTableRow' },
                                    React.createElement("td", { className: 'contractSectionCarTableCell contractSectionModelCell' }, ModelDictionary.nameFor(order.model.split('_')[0])),
                                    React.createElement("td", { className: 'contractSectionCarTableCell contractSectionAmountCell' }, order.quantity))); })
                                : null)))))));
}
