import React from 'react';
import './settlementSection.css';
import { vcpDiscountName } from 'common/vcpDiscountName';
import { TextAmount } from 'components/textAmount/TextAmount';
import { fundingName } from 'common/fundingName';
import { settlementStatus } from 'common/vcpSettlementStatus';
import ModelDictionary from 'common/modelDictionary';
import UserContext from 'common/userContext';
export function SettlementSection(_a) {
    var settlementSectionData = _a.settlementSectionData, onSettlementClick = _a.onSettlementClick;
    var settlements = settlementSectionData.settlements;
    function canShowAuditDocuments() {
        return UserContext.hasAnyRole('VCP_SALES', 'VCP_ACCOUNTING', 'VCP_AUDIT');
    }
    function handleOnSettlementClick(orderId) {
        if (canShowAuditDocuments()) {
            onSettlementClick(orderId);
        }
        return;
    }
    return (settlements ?
        React.createElement("div", { className: 'settlementSectionWrapper', "data-testid": 'settlement-section-table-wrapper' },
            React.createElement("table", { className: "settlementSectionTable" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementOrderDateCell', rowSpan: 2 }, "Data zam\u00F3wienia"),
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementDOVINCell', rowSpan: 2 },
                            "Dealer Order",
                            React.createElement("br", null),
                            "VIN"),
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementCarCell', rowSpan: 2 },
                            "Model",
                            React.createElement("br", null),
                            "Typ silnika"),
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementDiscountGroupCell', rowSpan: 2 },
                            "Oferta specjalna / Grupa rabatowa VCP",
                            React.createElement("br", null),
                            "Deklarowana w Vista"),
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementCatalogPriceCell', rowSpan: 2 }, "Cena katalogowa netto"),
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementClientDiscountNet', rowSpan: 2 }, "Rabat klienta netto"),
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementHeader', colSpan: 2 }, "Rabat VCP"),
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementFunding', rowSpan: 2 },
                            "Finansowanie",
                            React.createElement("br", null),
                            "Deklarowane w Vista"),
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementStatus', rowSpan: 2 }, "Status")),
                    React.createElement("tr", null,
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementClientDiscountPercent' }, "%"),
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementAmountNet' }, "kwota netto"))),
                React.createElement("tbody", null, settlements.map(function (settlement, index) { return (React.createElement("tr", { key: index, className: "settlementSectionTableRow ".concat(canShowAuditDocuments() ? 'clickableSettlementRow' : ''), onClick: function () { return handleOnSettlementClick(settlement.orderId); } },
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementOrderDateCell' }, settlement.orderDate),
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementDOVINCell' },
                        settlement.dealerOrder,
                        React.createElement("br", null),
                        settlement.vin),
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementCarCell' },
                        ModelDictionary.nameFor(settlement.modelId.split('_')[0]),
                        React.createElement("br", null),
                        settlement.fuelType,
                        React.createElement("br", null),
                        "(",
                        settlement.modelId.split('_')[1],
                        ")"),
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementDiscountGroupCell' }, vcpDiscountName(settlement.discount)),
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementCatalogPriceCell' },
                        React.createElement(TextAmount, { textAlignEnd: false }, settlement.listPrice)),
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementClientDiscountNet' },
                        React.createElement(TextAmount, { textAlignEnd: false }, settlement.clientDiscountNet)),
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementClientDiscountPercent' },
                        settlement.producerPercentDiscount,
                        " %"),
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementAmountNet' },
                        React.createElement(TextAmount, { textAlignEnd: false }, settlement.vcpDiscountNet)),
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementFunding' },
                        fundingName(settlement.funding),
                        React.createElement("br", null),
                        settlement.declaredFunding),
                    React.createElement("td", { className: 'settlementSectionBodyCell settlementStatus' }, settlementStatus(settlement.status)))); }))))
        : null);
}
