import { useHttp } from 'http/httpService';
import SessionStorage from 'common/sessionStorage';
import { creatorContextPath } from '../CreatorContextPath';
export function usePricesListApi() {
    var http = useHttp();
    var context = SessionStorage.get('employeeLeasingMode') ? 'employeeLeasing' : 'creator';
    return {
        getPricesList: function (year, modelName, isReadlOnly) {
            if (isReadlOnly === void 0) { isReadlOnly = false; }
            return http
                .get("/api/".concat(creatorContextPath(context), "/priceList/").concat(year, "/").concat(modelName, "?allPriceList=").concat(isReadlOnly))
                .then(function (response) { return response.data; });
        },
        getPricesListVcp: function (year, modelName, isReadlOnly) {
            if (isReadlOnly === void 0) { isReadlOnly = false; }
            return http
                .get("/api/".concat(creatorContextPath(context), "/priceList/vcp/").concat(year, "/").concat(modelName, "?allPriceList=").concat(isReadlOnly))
                .then(function (response) { return response.data; });
        },
        getPricesListForEL: function (modelName) {
            return http
                .get("/api/creator-el/priceList/".concat(modelName))
                .then(function (response) { return response.data; });
        }
    };
}
