export function selectedVcpDiscount(discount) {
    if (!discount || !discount.discountGroups) {
        return '';
    }
    function selectedName(group) {
        var selectedProfessionGroup = group.available.find(function (item) {
            return item.id === group.selected;
        });
        return selectedProfessionGroup ? selectedProfessionGroup.name : 'Nie wybrano';
    }
    function hasProfessionGroupSelected() {
        return discount.discountGroups.selected === 'PROFESSION_GROUP';
    }
    function hasDetailedSpecialOfferSelected() {
        return discount.discountGroups.selected === 'DETAILED_SPECIAL_OFFER';
    }
    function hasSpecialOfferGlobalMajorAccountSelected() {
        return discount.discountGroups.selected === 'DETAILED_SPECIAL_OFFER_GMA';
    }
    function hasSecondDiscountGroupGlobalMajorAccountSelected() {
        return discount.discountGroups.selected === 'SECOND_GMA';
    }
    var discountGroupName = selectedName(discount.discountGroups);
    if (hasProfessionGroupSelected()) {
        discountGroupName += ' - ' + selectedName(discount.professionGroups);
    }
    if (hasDetailedSpecialOfferSelected()) {
        discountGroupName += ' - ' + selectedName(discount.specialOfferTypes);
    }
    if (hasSpecialOfferGlobalMajorAccountSelected()) {
        discountGroupName += ' - ' + selectedName(discount.specialOfferGlobalMajorAccounts);
    }
    if (hasSecondDiscountGroupGlobalMajorAccountSelected()) {
        discountGroupName += ' - ' + selectedName(discount.secondDiscountGroupGlobalMajorAccounts);
    }
    return discountGroupName;
}
