import { useHttp } from 'http/httpService';
var searchMock = [
    {
        'commonOrderNumber': '10916786',
        'dealerOrder': '9999999',
        'vin': 'YV1XZK7V3R2215899',
        'modelId': '536',
        'modelYear': '2024',
        'registrationNumber': null,
        'stockCategory': null
    },
    {
        'commonOrderNumber': '10939999',
        'dealerOrder': '8888888',
        'vin': 'LYVUZK9V5PB318888',
        'modelId': '236',
        'modelYear': '2024',
        'registrationNumber': null,
        'stockCategory': 'IMPORTER_STOCK'
    },
    {
        'commonOrderNumber': '10935555',
        'dealerOrder': '7778889',
        'vin': null,
        'modelId': '137',
        'modelYear': '2024',
        'registrationNumber': null,
        'stockCategory': 'OTHER_DEALERS_STOCK'
    }
];
var selectedCarMock = {
    reservation: true,
    stockCategory: 'OWN_STOCK',
    modelId: '536',
    modelYear: '2024',
    listPrice: 200000,
    type: 'OWN',
    dealerOrder: '7135323',
    vin: 'LYVUZK9V5PB317867',
    deliveryDate: '2024-04-14',
    lastDateForOrderChange: '2024-03-31',
    drive: 'B4 (197 + 14 KM), 8-stopniowa automatyczna skrzynia biegów, Benzyna',
    version: 'Wersja: Plus, Motyw: Bright Dust',
    upholstery: 'Tapicerka tekstylna w tonacji czerni (Charcoal)',
    interior: 'wnętrze w tonacji czerni (Charcoal) (R100)',
    organizationName: 'DOM VOLVO Warszawa Puławska',
    commonOrderNumber: '10937770',
    productionDate: '2024-01-01',
    registrationNumber: null,
    comment: 'linia pierrwsza\nlinia druga\ntrzecia',
    warnings: [
        'Minęła data zamrożenia specyfikacji.',
        'Konfiguracja zostanie przedstawiona wg najnowszego cennika dla roku modelowego 2016.'
    ],
    oldCreatorAllowed: false
};
var modelsMock = [
    {
        value: '246',
        label: 'XC60'
    },
    {
        value: '536',
        label: 'XC40'
    },
    {
        value: '224',
        label: 'S60'
    },
    {
        value: '225',
        label: 'V60'
    },
    {
        value: '256',
        label: 'XC90'
    },
    {
        value: '235',
        label: 'V90'
    },
    {
        value: '227',
        label: 'V60 Cross Country'
    },
];
var modelYearsMock = [
    {
        label: '2019',
        value: '2019'
    },
    {
        label: '2020',
        value: '2020'
    },
    {
        label: '2021',
        value: '2021'
    },
    {
        label: '2022',
        value: '2022'
    },
    {
        label: '2023',
        value: '2023'
    },
    {
        label: '2024',
        value: '2024'
    },
    {
        label: '2025',
        value: '2025'
    },
    {
        label: '2026',
        value: '2026'
    }
];
var driveTypesMock = [
    {
        label: 'Spalinowy',
        value: 'ICE'
    },
    {
        label: 'Hybrydowy',
        value: 'MHEV'
    },
    {
        label: 'Elektryczny',
        value: 'BEV'
    }
];
var engineMock = [
    {
        label: 'B3 Mild Hybrid',
        value: 'K6'
    },
    {
        label: 'B4 Mild Hybrid',
        value: 'K9'
    },
    {
        label: 'T8 Plug-In Hybrid',
        value: 'BM'
    },
    {
        label: 'Rechage T8 Plug-in',
        value: 'H7'
    },
    {
        label: 'Single Motor',
        value: 'EM'
    },
    {
        label: 'Twin Motor',
        value: 'EV'
    },
];
var orderTypesMock = [
    {
        label: 'Stock',
        value: '30A'
    },
    {
        label: 'Ekspozycja',
        value: '31A'
    },
];
var carLocationsMock = [
    {
        label: 'Plac importera',
        value: 'importer'
    },
    {
        label: 'Plac dealera',
        value: 'dealer'
    },
];
var versionsMock = [
    {
        label: 'Base',
        value: 'base'
    },
    {
        label: 'Core',
        value: 'core'
    },
    {
        label: 'Plus Dark',
        value: 'plus dark'
    },
    {
        label: 'Plus Bright',
        value: 'plus bright'
    },
    {
        label: 'Polestar',
        value: 'polestar'
    }
];
var colorsMock = [
    {
        label: 'Vapour Grey',
        value: '74000'
    },
    {
        label: 'Crystal White',
        value: '70700'
    },
    {
        label: 'Fusion Red',
        value: '72500'
    },
    {
        label: 'Onyx Black',
        value: '71700'
    },
    {
        label: 'Sage Green',
        value: '73500'
    }
];
var upholsteryColorsMock = [
    {
        label: 'Tapicerka tekstylna City Block w tonacji szarości (Grey Melange)',
        value: 'R7C000'
    },
    {
        label: 'Tapicerka Connect z tkaniny zamszowej/Microtech w tonacji czerni (Charcoal)',
        value: 'R98000'
    },
    {
        label: 'Tapicerka skórzana w tonacji czerni (Charcoal)',
        value: 'RD0000'
    },
];
var priceRangesMock = [
    {
        label: '100 tys - 150 tys',
        value: '0'
    },
    {
        label: '150 tys - 200 tys',
        value: '1'
    },
    {
        label: '200 tys - 250 tys',
        value: '2'
    },
    {
        label: '250 tys - 300 tys',
        value: '3'
    },
    {
        label: 'pow. 300 tys',
        value: '4'
    },
];
var priceRangesMock2 = [
    {
        label: '100 tys - 150 tys',
        from: 100,
        to: 150
    },
    {
        label: '150 tys - 200 tys',
        from: 150,
        to: 200
    },
    {
        label: '200 tys - 250 tys',
        from: 200,
        to: 250
    },
    {
        label: '250 tys - 300 tys',
        from: 250,
        to: 300
    },
    {
        label: 'pow. 300 tys',
        from: 300,
        to: null
    },
];
var stockOptionsMock = [
    {
        label: 'Dealera',
        value: 'dealer'
    },
    {
        label: 'Innego dealera',
        value: 'other dealer'
    },
];
var emptyMockCars = {
    'content': [],
    'pageable': {
        'pageNumber': 0,
        'pageSize': 10,
        'sort': [
            {
                'direction': 'ASC',
                'property': 'deliveryDate',
                'ignoreCase': false,
                'nullHandling': 'NATIVE',
                'descending': false,
                'ascending': true
            }
        ],
        'offset': 0,
        'paged': true,
        'unpaged': false
    },
    'totalPages': 1,
    'totalElements': 0,
};
var mockCars = {
    'content': [
        {
            'reservation': false,
            'stockCategory': null,
            'modelYear': 2024,
            'listPrice': null,
            'type': 'PRO',
            'dealerOrder': '8273310',
            'vin': null,
            'deliveryDate': null,
            'lastDateForOrderChange': '2023-12-21',
            'drive': null,
            'version': null,
            'upholstery': null,
            'interior': null,
            'organizationId': 'PL025',
            'organizationName': 'V-Motors Sp. z o.o.',
            'commonOrderNumber': '11223447',
            'productionDate': '2024-01-22',
            'registrationNumber': '',
            'comment': null,
            'warnings': [
                'komunikat 1',
                'Minęła data zamrożenia specyfikacji.'
            ],
            'oldCreatorAllowed': false,
            'factoryOrderDate': '2023-06-26',
            'fyon': '686829904',
            'modelId': '416',
            'structureWeek': '202403',
            'carOnLineDate': '2024-01-16 00:00:00',
            'pno34': null,
            'fixedPlannedDate': null,
            'isSold': false,
            engineDescription: 'opis napędu rozszerzony',
        },
        {
            'reservation': false,
            'stockCategory': null,
            'modelYear': 2024,
            'listPrice': null,
            'type': 'INNY',
            'dealerOrder': '8707849',
            'vin': null,
            'deliveryDate': null,
            'lastDateForOrderChange': '2024-01-04',
            'drive': null,
            'version': null,
            'upholstery': null,
            'interior': null,
            'organizationId': 'PL050',
            'organizationName': 'Euroservice Spółka Jawna Marek Janowski-Józef Czech',
            'commonOrderNumber': '11659425',
            'productionDate': '2024-02-02',
            'registrationNumber': '',
            'comment': null,
            'warnings': [],
            'oldCreatorAllowed': false,
            'factoryOrderDate': '2023-12-19',
            'fyon': '687295188',
            'modelId': '416',
            'structureWeek': '202405',
            'carOnLineDate': '2024-01-30 00:00:00',
            'pno34': null,
            'fixedPlannedDate': null,
            'isSold': false
        },
        {
            'reservation': false,
            'stockCategory': null,
            'modelYear': 2024,
            'listPrice': null,
            'type': 'INNY',
            'dealerOrder': '8730578',
            'vin': null,
            'deliveryDate': null,
            'lastDateForOrderChange': '2024-02-01',
            'drive': null,
            'version': null,
            'upholstery': null,
            'interior': null,
            'organizationId': 'PL057',
            'organizationName': 'Firma Karlik spółka jawna',
            'commonOrderNumber': '11680501',
            'productionDate': '2024-03-01',
            'registrationNumber': '',
            'comment': null,
            'warnings': ['model-year-changed', 'freezed-specification', 'another-warning'],
            'oldCreatorAllowed': false,
            'factoryOrderDate': '2024-01-03',
            'fyon': '687361675',
            'modelId': '416',
            'structureWeek': '202409',
            'carOnLineDate': '2024-02-27 00:00:00',
            'pno34': null,
            'fixedPlannedDate': null,
            'isSold': false
        },
        {
            'reservation': false,
            'stockCategory': null,
            'modelYear': 2024,
            'listPrice': null,
            'type': 'PRO',
            'dealerOrder': '8399124',
            'vin': null,
            'deliveryDate': null,
            'lastDateForOrderChange': '2024-03-14',
            'drive': null,
            'version': null,
            'upholstery': null,
            'interior': null,
            'organizationId': 'PL057',
            'organizationName': 'Firma Karlik spółka jawna',
            'commonOrderNumber': '11348081',
            'productionDate': '2024-04-13',
            'registrationNumber': '',
            'comment': null,
            'warnings': ['https://github.com/VolvoCarPoland/volvo-dol/pull/1554'],
            'oldCreatorAllowed': false,
            'factoryOrderDate': '2023-08-09',
            'fyon': '686930165',
            'modelId': '416',
            'structureWeek': '202415',
            'carOnLineDate': '2024-04-12 00:00:00',
            'pno34': null,
            'fixedPlannedDate': null,
            'isSold': false
        },
        {
            'reservation': false,
            'stockCategory': null,
            'modelYear': 2024,
            'listPrice': null,
            'type': 'PRO',
            'dealerOrder': '8399121',
            'vin': null,
            'deliveryDate': null,
            'lastDateForOrderChange': '2024-03-14',
            'drive': null,
            'version': null,
            'upholstery': null,
            'interior': null,
            'organizationId': 'PL057',
            'organizationName': 'Firma Karlik spółka jawna',
            'commonOrderNumber': '11348078',
            'productionDate': '2024-04-23',
            'registrationNumber': '',
            'comment': null,
            'warnings': null,
            'oldCreatorAllowed': false,
            'factoryOrderDate': '2023-08-09',
            'fyon': '686930132',
            'modelId': '416',
            'structureWeek': '202416',
            'carOnLineDate': '2024-04-15 00:00:00',
            'pno34': null,
            'fixedPlannedDate': null,
            'isSold': false
        },
        {
            'reservation': false,
            'stockCategory': null,
            'modelYear': 2025,
            'listPrice': null,
            'type': 'PRO',
            'dealerOrder': '8429292',
            'vin': null,
            'deliveryDate': null,
            'lastDateForOrderChange': '2024-05-23',
            'drive': null,
            'version': null,
            'upholstery': null,
            'interior': null,
            'organizationId': 'PL043',
            'organizationName': 'Wadowscy Sp. z o.o.',
            'commonOrderNumber': '11378848',
            'productionDate': '2024-06-20',
            'registrationNumber': '',
            'comment': null,
            'warnings': null,
            'oldCreatorAllowed': false,
            'factoryOrderDate': '2023-08-28',
            'fyon': '689018752',
            'modelId': '416',
            'structureWeek': '202425',
            'carOnLineDate': '2024-06-06 00:00:00',
            'pno34': null,
            'fixedPlannedDate': null,
            'isSold': false
        },
        {
            'reservation': false,
            'stockCategory': null,
            'modelYear': 2025,
            'listPrice': null,
            'type': 'PRO',
            'dealerOrder': '8717625',
            'vin': null,
            'deliveryDate': null,
            'lastDateForOrderChange': '2024-06-13',
            'drive': null,
            'version': null,
            'upholstery': null,
            'interior': null,
            'organizationId': 'PL042',
            'organizationName': 'Firma Karlik spółka jawna',
            'commonOrderNumber': '11673132',
            'productionDate': '2024-07-05',
            'registrationNumber': '',
            'comment': null,
            'warnings': null,
            'oldCreatorAllowed': false,
            'factoryOrderDate': '2023-12-28',
            'fyon': '689144079',
            'modelId': '416',
            'structureWeek': '202428',
            'carOnLineDate': '2024-07-05 00:00:00',
            'pno34': null,
            'fixedPlannedDate': null,
            'isSold': false
        },
        {
            'reservation': false,
            'stockCategory': null,
            'modelYear': 2025,
            'listPrice': 330000,
            'type': 'INNY',
            'dealerOrder': '8769935',
            'vin': null,
            'deliveryDate': null,
            'lastDateForOrderChange': '2024-09-19',
            'drive': null,
            'version': null,
            'upholstery': null,
            'interior': null,
            'organizationId': 'PL055',
            'organizationName': 'Nord Auto Sp. z o.o.',
            'commonOrderNumber': '11718908',
            'productionDate': '2024-09-27',
            'registrationNumber': '',
            'comment': null,
            'warnings': null,
            'oldCreatorAllowed': false,
            'factoryOrderDate': '2024-01-17',
            'fyon': '689143881',
            'modelId': '416',
            'structureWeek': '202442',
            'carOnLineDate': '2024-10-18 00:00:00',
            'pno34': null,
            'fixedPlannedDate': null,
            'isSold': false
        },
        {
            'reservation': false,
            'stockCategory': null,
            'modelYear': 2025,
            'listPrice': null,
            'type': 'INNY',
            'dealerOrder': '8415798',
            'vin': null,
            'deliveryDate': null,
            'lastDateForOrderChange': '2024-09-12',
            'drive': null,
            'version': null,
            'upholstery': null,
            'interior': null,
            'organizationId': 'PL068',
            'organizationName': 'Polmax Group Sp. z o.o.',
            'commonOrderNumber': '11367131',
            'productionDate': '2024-09-25',
            'registrationNumber': '',
            'comment': null,
            'warnings': null,
            'oldCreatorAllowed': false,
            'factoryOrderDate': '2023-08-18',
            'fyon': '689018646',
            'modelId': '416',
            'structureWeek': '202441',
            'carOnLineDate': '2024-10-11 00:00:00',
            'pno34': null,
            'fixedPlannedDate': null,
            'isSold': false
        },
        {
            'reservation': false,
            'stockCategory': null,
            'modelYear': 2025,
            'listPrice': null,
            'type': 'INNY',
            'dealerOrder': '8877715',
            'vin': null,
            'deliveryDate': null,
            'lastDateForOrderChange': '2024-09-19',
            'drive': null,
            'version': null,
            'upholstery': null,
            'interior': null,
            'organizationId': 'PL043',
            'organizationName': 'Wadowscy Sp. z o.o.',
            'commonOrderNumber': '11829963',
            'productionDate': '2024-09-27',
            'registrationNumber': '',
            'comment': null,
            'warnings': null,
            'oldCreatorAllowed': false,
            'factoryOrderDate': '2024-03-13',
            'fyon': '689145332',
            'modelId': '416',
            'structureWeek': '202442',
            'carOnLineDate': '2024-09-13 00:00:00',
            'pno34': null,
            'fixedPlannedDate': null,
            'isSold': false
        }
    ],
    'pageable': {
        'pageNumber': 0,
        'pageSize': 10,
        'sort': [
            {
                'direction': 'ASC',
                'property': 'deliveryDate',
                'ignoreCase': false,
                'nullHandling': 'NATIVE',
                'descending': false,
                'ascending': true
            }
        ],
        'offset': 0,
        'paged': true,
        'unpaged': false
    },
    'totalPages': 2,
    'totalElements': 13,
    'last': false,
    'numberOfElements': 10,
    'size': 10,
    'number': 0,
    'sort': [
        {
            'direction': 'ASC',
            'property': 'deliveryDate',
            'ignoreCase': false,
            'nullHandling': 'NATIVE',
            'descending': false,
            'ascending': true
        }
    ],
    'first': true,
    'empty': false
};
var mockCars1 = {
    content: [
        {
            reservation: true,
            stockCategory: 'OWN_STOCK',
            modelId: '416',
            modelYear: 2025,
            listPrice: 200000,
            type: 'OWN',
            dealerOrder: '7135323',
            vin: 'LYVUZK9V5PB317867',
            deliveryDate: '2024-04-14',
            lastDateForOrderChange: '2024-03-31',
            engine: 'B4',
            drive: 'B4 (197 + 14 KM), 8-stopniowa automatyczna skrzynia biegów, Benzyna',
            version: 'Wersja: Plus, Motyw: Bright Dust',
            upholstery: 'Tapicerka tekstylna w tonacji czerni (Charcoal)',
            color: 'Vapour Grey',
            interior: 'wnętrze w tonacji czerni (Charcoal) (R100)',
            organizationName: 'DOM VOLVO Warszawa Puławska',
            commonOrderNumber: '11332156',
            productionDate: '2024-01-01',
            registrationNumber: null,
            comment: 'Doposażeniu auta w akcesoria pozafabryczne',
            warnings: [
                'model-year-changed',
                'freezed-specification'
            ],
            oldCreatorAllowed: false,
            factoryOrderDate: '2023-08-04',
            fyon: '688936871',
            fixedPlannedDate: '2024-02-10',
            engineDescription: 'opis napędu rozszerzony',
            pno34: null,
            organizationId: 'PL301',
            isSold: false,
            carOnLineDate: '2024-01-16 00:00:00',
            structureWeek: '202403',
            'images': {
                'EXTERIOR_right': 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v3/17/74000/R7D000/R13E/TC05///TP04///GR01/T101/TJ03/NP02/TM02////JB0A/T21A/LF01//VP09//FH02/T006////default.jpg?market=pl&client=dmspl&angle=1&w=1920&bg=descriptive-studio',
                'TYRE_LABEL_TYRE_LABEL': 'https://vcc-tyre-label.s3.eu-west-1.amazonaws.com/32327075.jpg',
                'COLOR_COLOR': 'https://cas.volvocars.com/image/v2/icons/MY24_2317/246/10/74000.jpg?client=dmspl&w=440',
                'EXTERIOR_rear': 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v3/17/74000/R7D000/R13E/TC05///TP04///GR01/T101/TJ03/NP02/TM02////JB0A/T21A/LF01//VP09//FH02/T006////default.jpg?market=pl&client=dmspl&angle=6&w=1920&bg=descriptive-studio',
                'EXTERIOR_left': 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v3/17/74000/R7D000/R13E/TC05///TP04///GR01/T101/TJ03/NP02/TM02////JB0A/T21A/LF01//VP09//FH02/T006////default.jpg?market=pl&client=dmspl&angle=7&w=1920&bg=descriptive-studio',
                'INTERIOR_seat': 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/seat-view-v1/17/1/74000/R7D000/NP02////LF01/FH02/default.jpg?market=pl&client=dmspl&angle=0&w=1920',
                'INTERIOR_steeringWheel': 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/drivers-view-v2/17/1/74000/R7D000/NC09/DI0B/RU0C//NP02///K502//LF01//FH02/PN03//SteeringWheel.jpg?market=pl&client=dmspl&w=1920',
                'INTERIOR_rear': 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/rear-view-v1/17/1/74000/R7D000/NP02////LF01/FH02/default.jpg?market=pl&client=dmspl&angle=0&w=1920',
                'EXTERIOR_threeQuartersFrontLeft': 'https://cas.volvocars.com/image/dynamic/MY25_2417/246/exterior-v2/R5/73500/RC2000/R18C/TC05/_/2G03/TP05/_/JT02/GR02/T102/TJ02/NP02/TM04/JG02/_/_/JB0C/T21B/LF05/_/VP07/_/FH01/T006/_/_/_/default.jpg?market=pl&client=dmspl&angle=4&w=1920&bg=descriptive-studio',
                'WHEEL_WHEEL': 'https://cas.volvocars.com/image/v2/icons/MY24_2317/246/102/R13E.jpg?client=dmspl&w=440',
                'INTERIOR_side': 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/interior-v2/17/1/74000/R7D000/NC09/DI0B/RU0C/NV03//NJ01/NP02///K502//LF01//FH02/PN03//default.jpg?market=pl&client=dmspl&angle=0&w=1920',
                'INTERIOR_driversView': 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/drivers-view-v2/17/1/74000/R7D000/NC09/DI0B/RU0C//NP02///K502//LF01//FH02/PN03//default.jpg?market=pl&client=dmspl&angle=0&w=1920',
                'INTERIOR_gearShift': 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/drivers-view-v2/17/1/74000/R7D000/NC09/DI0B/RU0C//NP02///K502//LF01//FH02/PN03//GearShift.jpg?market=pl&client=dmspl&w=1534',
                'EXTERIOR_threeQuartersRearLeft': 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v3/17/74000/R7D000/R13E/TC05///TP04///GR01/T101/TJ03/NP02/TM02////JB0A/T21A/LF01//VP09//FH02/T006////default.jpg?market=pl&client=dmspl&angle=5&w=1920&bg=descriptive-studio',
                'EXTERIOR_front': 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v3/17/74000/R7D000/R13E/TC05///TP04///GR01/T101/TJ03/NP02/TM02////JB0A/T21A/LF01//VP09//FH02/T006////default.jpg?market=pl&client=dmspl&angle=3&w=1920&bg=descriptive-studio',
                'INTERIOR_decor': 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/deco-view-v1/17/1/74000/R7D000/NC09//NP02///K502//LF01//FH02//default.jpg?market=pl&client=dmspl&angle=0&w=1920'
            },
        },
        {
            reservation: false,
            stockCategory: 'OTHER_DEALERS_STOCK',
            modelId: '225',
            modelYear: 2024,
            listPrice: 280000,
            type: 'TRADEABLE',
            dealerOrder: '7135666',
            vin: 'LYVUZK9V5PB317666',
            deliveryDate: '2024-12-25',
            lastDateForOrderChange: '2024-11-30',
            engine: null,
            drive: 'B4 (197 + 14 KM), 8-stopniowa automatyczna skrzynia biegów, Benzyna',
            version: 'Wersja: Plus, Motyw: Bright Dust',
            upholstery: 'Tapicerka tekstylna w tonacji czerni (Charcoal)',
            interior: 'wnętrze w tonacji czerni (Charcoal) (R100)',
            color: null,
            organizationName: 'DOM VOLVO Warszawa Puławska',
            commonOrderNumber: '10288662',
            productionDate: '2024-01-01',
            registrationNumber: 'ABC12312',
            comment: null,
            warnings: null,
            oldCreatorAllowed: true,
            factoryOrderDate: '2022-06-24',
            fyon: '679825851',
            carOnLineDate: '2023-06-09',
            structureWeek: '202324',
            pno34: null,
            organizationId: 'PL301',
            isSold: false,
            fixedPlannedDate: null,
            'images': {
                EXTERIOR_front: 'https://cas.volvocars.com/image/dynamic/MY25_2417/235/exterior-v1/V5/72300/RC2000/R15D/TC05/_/2G03/TP05/LR02/_/GR02/T103/TJ02/NP02/TM04/JG02/CB03/_/JB0C/T212/LF05/_/VP07/FH01/_/_/_/_/default.jpg?market=pl&client=car-shop&angle=3&w=1920&bg=descriptive-studio',
                'TYRE_LABEL_TYRE_LABEL': 'https://vcc-tyre-label.s3.eu-west-1.amazonaws.com/32327075.jpg',
                'COLOR_COLOR': 'https://cas.volvocars.com/image/v2/icons/MY24_2317/246/10/74000.jpg?client=dmspl&w=440',
                'EXTERIOR_rear': 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v3/17/74000/R7D000/R13E/TC05///TP04///GR01/T101/TJ03/NP02/TM02////JB0A/T21A/LF01//VP09//FH02/T006////default.jpg?market=pl&client=dmspl&angle=6&w=1920&bg=descriptive-studio',
                'EXTERIOR_left': 'https://cas.volvocars.com/image/dynamic/MY24_2317/246/exterior-v3/17/74000/R7D000/R13E/TC05///TP04///GR01/T101/TJ03/NP02/TM02////JB0A/T21A/LF01//VP09//FH02/T006////default.jpg?market=pl&client=dmspl&angle=7&w=1920&bg=descriptive-studio',
            },
        },
        {
            reservation: false,
            stockCategory: 'OWN_STOCK',
            modelId: '536',
            modelYear: 2024,
            listPrice: 200000,
            type: 'OWN',
            dealerOrder: '7135333',
            vin: 'LYVUZK9V5PB317333',
            deliveryDate: '2024-10-10',
            lastDateForOrderChange: '2024-09-01',
            engine: null,
            drive: 'B4 (197 + 14 KM), 8-stopniowa automatyczna skrzynia biegów, Benzyna',
            // version: 'Wersja: Plus, Motyw: Bright Dust',
            version: null,
            color: null,
            // upholstery: 'Tapicerka tekstylna w tonacji czerni (Charcoal)',
            upholstery: null,
            interior: 'wnętrze w tonacji czerni (Charcoal) (R100)',
            organizationName: 'DOM VOLVO Warszawa Puławska',
            commonOrderNumber: '10937333',
            productionDate: '2024-01-01',
            registrationNumber: null,
            comment: 'Doposażeniu auta w akcesoria pozafabryczne',
            warnings: [],
            oldCreatorAllowed: false,
            factoryOrderDate: '2023-10-04',
            fyon: '688936111',
            pno34: null,
            organizationId: 'PL301',
            isSold: false,
            carOnLineDate: '2024-01-16 00:00:00',
            structureWeek: '202403',
            fixedPlannedDate: null,
            images: []
        },
        {
            reservation: true,
            stockCategory: 'IMPORTER_STOCK',
            modelId: '539',
            modelYear: 2024,
            listPrice: 330000,
            type: 'OTHER',
            dealerOrder: '7135777',
            vin: 'LYVUZK9V5PB317777',
            deliveryDate: '2024-07-07',
            lastDateForOrderChange: '2024-06-01',
            engine: 'B4',
            drive: 'B4 (197 + 14 KM), 8-stopniowa automatyczna skrzynia biegów, Benzyna',
            version: 'Wersja: Plus, Motyw: Bright Dust',
            upholstery: 'Tapicerka tekstylna w tonacji czerni (Charcoal)',
            color: null,
            interior: 'wnętrze w tonacji czerni (Charcoal) (R100)',
            organizationName: 'DOM VOLVO Warszawa Puławska',
            commonOrderNumber: '10937777',
            productionDate: '2024-01-01',
            registrationNumber: null,
            comment: null,
            warnings: [''],
            oldCreatorAllowed: false,
            factoryOrderDate: '2023-01-01',
            fyon: '688936000',
            pno34: null,
            organizationId: 'PL301',
            isSold: false,
            carOnLineDate: '2024-01-16 00:00:00',
            structureWeek: '202403',
            fixedPlannedDate: null,
            'images': {
                'TYRE_LABEL_TYRE_LABEL': 'https://vcc-tyre-label.s3.eu-west-1.amazonaws.com/32327075.jpg',
                'COLOR_COLOR': 'https://cas.volvocars.com/image/v2/icons/MY24_2317/246/10/74000.jpg?client=dmspl&w=440',
            },
        },
    ],
    totalPages: 8,
    totalElements: 79
};
var filtersMock = {
    MODEL_YEAR: [
        {
            label: '2019',
            value: '2019'
        },
        {
            label: '2019',
            value: '2019'
        },
        {
            label: '2019',
            value: '2019'
        },
        {
            label: '2019',
            value: '2019'
        },
        {
            label: null,
            value: null
        },
        {
            label: 'none',
            value: 'Brak'
        },
        {
            label: 'NONE',
            value: 'Brak danych'
        },
        {
            label: '2020',
            value: '2020'
        },
        {
            label: '2021',
            value: '2021'
        },
        {
            label: '2022',
            value: '2022'
        },
        {
            label: '2023',
            value: '2023'
        },
        {
            label: '2024',
            value: '2024'
        },
        {
            label: '2025',
            value: '2025'
        },
        {
            label: '2026',
            value: '2026'
        }
    ],
    DRIVE_TYPE: [
        {
            label: 'Spalinowy',
            value: 'ICE'
        },
        {
            label: 'Hybrydowy',
            value: 'MHEV'
        },
        {
            label: 'Elektryczny',
            value: 'BEV'
        }
    ],
    ENGINE: [
        {
            label: 'B3 Mild Hybrid',
            value: 'K6'
        },
        {
            label: 'B4 Mild Hybrid',
            value: 'K9'
        },
        {
            label: 'T8 Plug-In Hybrid',
            value: 'BM'
        },
        {
            label: 'Rechage T8 Plug-in',
            value: 'H7'
        },
        {
            label: 'Single Motor',
            value: 'EM'
        },
        {
            label: 'Twin Motor',
            value: 'EV'
        },
    ],
    ORDER_TYPE: [
        {
            label: 'Stock',
            value: '30A'
        },
        {
            label: 'Ekspozycja',
            value: '31A'
        },
    ],
    CAR_LOCATION: [
        {
            label: 'Plac importera',
            value: 'importer'
        },
        {
            label: 'Plac dealera',
            value: 'dealer'
        },
    ],
    VERSION: [
        {
            label: 'Base',
            value: 'base'
        },
        {
            label: 'Core',
            value: 'core'
        },
        {
            label: 'Plus Dark',
            value: 'plus dark'
        },
        {
            label: 'Plus Bright',
            value: 'plus bright'
        },
        {
            label: 'Polestar',
            value: 'polestar'
        }
    ],
    COLOR: [
        {
            label: 'Vapour Grey',
            value: '74000'
        },
        {
            label: 'Crystal White',
            value: '70700'
        },
        {
            label: 'Fusion Red',
            value: '72500'
        },
        {
            label: 'Onyx Black',
            value: '71700'
        },
        {
            label: 'Sage Green',
            value: '73500'
        }
    ],
    UPHOLSTERY: [
        {
            label: 'Tapicerka tekstylna City Block w tonacji szarości (Grey Melange)',
            value: 'R7C000'
        },
        {
            label: 'Tapicerka Connect z tkaniny zamszowej/Microtech w tonacji czerni (Charcoal)',
            value: 'R98000'
        },
        {
            label: 'Tapicerka skórzana w tonacji czerni (Charcoal)',
            value: 'RD0000'
        },
    ],
    STOCK: [
        {
            label: 'Dealera',
            value: 'dealer'
        },
        {
            label: 'Innego dealera',
            value: 'other dealer'
        },
    ]
};
var filtersMockResponse = {
    status: 200,
    data: {
        MODEL_YEAR: [
            {
                label: '2019',
                value: '2019'
            },
            {
                label: '2019',
                value: '2019'
            },
            {
                label: '2019',
                value: '2019'
            },
            {
                label: '2019',
                value: '2019'
            },
            {
                label: null,
                value: null
            },
            {
                label: 'none',
                value: 'Brak'
            },
            {
                label: 'NONE',
                value: 'Brak danych'
            },
            {
                label: '2020',
                value: '2020'
            },
            {
                label: '2021',
                value: '2021'
            },
            {
                label: '2022',
                value: '2022'
            },
            {
                label: '2023',
                value: '2023'
            },
            {
                label: '2024',
                value: '2024'
            },
            {
                label: '2025',
                value: '2025'
            },
            {
                label: '2026',
                value: '2026'
            }
        ],
        DRIVE_TYPE: [
            {
                label: 'Spalinowy',
                value: 'ICE'
            },
            {
                label: 'Hybrydowy',
                value: 'MHEV'
            },
            {
                label: 'Elektryczny',
                value: 'BEV'
            }
        ],
        ENGINE: [
            {
                label: 'B3 Mild Hybrid',
                value: 'K6'
            },
            {
                label: 'B4 Mild Hybrid',
                value: 'K9'
            },
            {
                label: 'T8 Plug-In Hybrid',
                value: 'BM'
            },
            {
                label: 'Rechage T8 Plug-in',
                value: 'H7'
            },
            {
                label: 'Single Motor',
                value: 'EM'
            },
            {
                label: 'Twin Motor',
                value: 'EV'
            },
        ],
        ORDER_TYPE: [
            {
                label: 'Stock',
                value: '30A'
            },
            {
                label: 'Ekspozycja',
                value: '31A'
            },
        ],
        CAR_LOCATION: [
            {
                label: 'Plac importera',
                value: 'importer'
            },
            {
                label: 'Plac dealera',
                value: 'dealer'
            },
        ],
        VERSION: [
            {
                'value': 'Wersja: Plus',
                'label': 'Wersja: Plus'
            },
            {
                'value': 'Wersja: Ultra',
                'label': 'Wersja: Ultra'
            },
            {
                'value': 'Wersja: Core',
                'label': 'Wersja: Core'
            },
            {
                'value': 'Wersja: Essential',
                'label': 'Wersja: Essential'
            },
            {
                'value': null,
                'label': null
            },
            {
                'value': 'wersja: Ultimate',
                'label': 'wersja: Ultimate'
            },
            {
                'value': 'Wersja: Nowa',
                'label': 'Wersja: Nowa'
            },
            {
                'value': 'Wersja: Superuber',
                'label': 'Wersja: Superuber'
            }
        ],
        COLOR: [
            {
                label: 'Vapour Grey',
                value: '74000'
            },
            {
                label: 'Crystal White',
                value: '70700'
            },
            {
                label: 'Fusion Red',
                value: '72500'
            },
            {
                label: 'Onyx Black',
                value: '71700'
            },
            {
                label: 'Sage Green',
                value: '73500'
            }
        ],
        UPHOLSTERY: [
            {
                label: 'Tapicerka tekstylna City Block w tonacji szarości (Grey Melange)',
                value: 'R7C000'
            },
            {
                label: 'Tapicerka Connect z tkaniny zamszowej/Microtech w tonacji czerni (Charcoal)',
                value: 'R98000'
            },
            {
                label: 'Tapicerka skórzana w tonacji czerni (Charcoal)',
                value: 'RD0000'
            },
        ],
        STOCK: [
            {
                label: 'Dealera',
                value: 'SELF'
            },
            {
                label: 'Innego dealera',
                value: 'OTHER_DEALERS'
            },
        ]
    }
};
var emptyFiltersResponse = {
    status: 200,
    data: {}
};
export function useNewOfferFromStockApi() {
    var http = useHttp();
    return {
        searchCars: function (type, phrase) {
            return http
                .get("/api/car/".concat(type, "?phrase=").concat(phrase))
                .then(function (response) { return response.data; });
            // .then((response) => searchMock);
        },
        getCar: function (con) {
            return http
                .get("/api/car/stock-cars/commonOrderNumber/".concat(con))
                // .then((response) => selectedCarMock);
                .then(function (response) { return response.data; });
        },
        checkCar: function (offerId, fyon, clientId, partyId, modelYear, factoryOrderDate, con, fixedPlannedDate) {
            return http
                .post('/api/creator/offer/stock', {
                offerId: offerId,
                fyon: fyon,
                clientId: clientId,
                partyId: partyId,
                modelYear: modelYear,
                factoryOrderDate: factoryOrderDate,
                con: con,
                fixedPlannedDate: fixedPlannedDate
            });
        },
        getAvailableModels: function () {
            return http
                .get('/api/car/stock-cars/models')
                .then(function (response) { return response.data; });
            // .then((response) => modelsMock);
        },
        getAvailableOptions: function (filters) {
            return http
                .post('/api/car/stock-cars/filters', filters)
                .then(function (response) {
                return response;
                // return filtersMockResponse;
                // return emptyFiltersResponse;
            });
        },
        showFilteredCars: function (pno3, body, sorting, page, size) {
            var searchParams = new URLSearchParams();
            searchParams.set('sort', sorting);
            searchParams.set('page', page);
            searchParams.set('size', size);
            return http
                .post("/api/car/stock-cars/model/".concat(pno3, "?").concat(searchParams), body)
                .then(function (response) { return response.data; });
            // .then((response) => mockCars1);
            // .then((response) => emptyMockCars);
        }
    };
}
