var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { stockCategoryDictionary } from 'common/stockCategoryDictionary';
import { Button } from 'components/button/Button.component';
import { InfoIcon } from 'components/infoIcon/InfoIcon.component';
import { Label } from 'components/label/Label.component';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
import { HorizontalLine } from 'pages/specialOffer/horizontalLine/HorizontalLine';
import React from 'react';
import AsyncSelect from 'react-select/async';
import ModelDictionary from 'common/modelDictionary';
import { Dropdown } from 'components/dropdown/Dropdown.component';
import { RemoveIcon } from 'components/dropdown/RemoveIcon.component';
import { MultiDropdownWithoutChips } from 'components/dropdown/MultiDropdownWithoutChips';
import { DateRangePicker } from 'components/datepicker/DateRangePicker';
import { format } from 'date-fns';
import { CarsListSection } from '../CarsList/CarsListSection.component';
import { useSearchCarSection, dateRangeFilterKeys } from './useSearchCarSection';
import { immutableSort } from 'utils/immutableSort';
import { versionOptionsOrdering } from './versionOptionsOrdering';
var generateStockCarOptionLabel = function (e, searchingType) {
    return ((searchingType === 'sold-cars' ? '' : (e.stockCategory ? stockCategoryDictionary(e.stockCategory) + ', ' : 'brak, ')) +
        ModelDictionary.nameFor(e.modelId) +
        ' ' +
        "(".concat(e.modelYear, ")") +
        ', ' +
        "".concat(e.dealerOrder ? 'DO: ' + e.dealerOrder + ',' : '') +
        ' ' +
        "".concat(e.commonOrderNumber ? 'CON: ' + e.commonOrderNumber : '') +
        "".concat(e.vin ? ', ' + e.vin : '') +
        "".concat(e.registrationNumber ? ', ' + e.registrationNumber : ''));
};
export var sortingAvailableOptions = [
    {
        label: 'najwcześniejsza data dostawy',
        value: 'deliveryDate,asc'
    },
    {
        label: 'najpóźniejsza data dostawy',
        value: 'deliveryDate,desc'
    },
    {
        label: 'najwcześniejsza data zamrożenia specyfikacji',
        value: 'lastDateForOrderChange,asc'
    },
    {
        label: 'najpóźniejsza data zamrożenia specyfikacji',
        value: 'lastDateForOrderChange,desc'
    },
    {
        label: 'najniższa cena katalogowa',
        value: 'listPrice,asc'
    },
    {
        label: 'najwyższa cena katalogowa',
        value: 'listPrice,desc'
    }
];
var stockOptions = [
    {
        label: 'Dealera',
        value: 'SELF'
    },
    {
        label: 'Stock importera',
        value: 'IMPORTER'
    },
    {
        label: 'Innego dealera',
        value: 'OTHER_DEALERS'
    }
];
export function SearchCarSection(_a) {
    var handleSetAppError = _a.handleSetAppError;
    var _b = useSearchCarSection(), searchingType = _b.searchingType, handleChangeSearchingType = _b.handleChangeSearchingType, stockCarSearchChangeHandler = _b.stockCarSearchChangeHandler, filtersOptions = _b.filtersOptions, loadOptions = _b.loadOptions, selectedFilters = _b.selectedFilters, onModelChange = _b.onModelChange, onFilterChange = _b.onFilterChange, onDateRangeChange = _b.onDateRangeChange, onDeleteFilter = _b.onDeleteFilter, clearAllSelectedFilters = _b.clearAllSelectedFilters, seletedSorting = _b.seletedSorting, onSortingChange = _b.onSortingChange, currentPage = _b.currentPage, totalPages = _b.totalPages, changePageHandler = _b.changePageHandler, stockCars = _b.stockCars, onSearchClick = _b.onSearchClick, totalCars = _b.totalCars, carsDataAreUpToDate = _b.carsDataAreUpToDate, noMatchedCars = _b.noMatchedCars;
    var isModelSelected = !!(selectedFilters && selectedFilters.model);
    var selectedOptions = selectedFilters ? Object.entries(selectedFilters) : [];
    var searchIsDisabled = !isModelSelected ||
        carsDataAreUpToDate ||
        (selectedFilters.deliveryDate &&
            selectedFilters.deliveryDate[0] !== null &&
            selectedFilters.deliveryDate[1] === null) ||
        (selectedFilters.lastDateForOrderChange &&
            selectedFilters.lastDateForOrderChange[0] !== null &&
            selectedFilters.lastDateForOrderChange[1] === null);
    var clearFiltersDisabled = !selectedFilters;
    var versionLabelsFormatted = filtersOptions && filtersOptions['versions'] ? filtersOptions['versions'].map(function (version) {
        return {
            value: version.value,
            label: version.label.toString().replace(/Wersja: /i, '')
        };
    }) : [];
    var sortedModelYearsOptions = filtersOptions && filtersOptions['modelYears'] ? immutableSort(filtersOptions['modelYears'], function (a, b) { return Number(b.value) - Number(a.value); }) : [];
    var sortedEngineOptions = filtersOptions && filtersOptions['engines'] ? immutableSort(filtersOptions['engines'], function (a, b) { return a.label.toString().localeCompare(b.label.toString()); }) : [];
    var sortedVersionOptions = versionLabelsFormatted.length > 0 ? immutableSort(versionLabelsFormatted, function (versionA, versionB) {
        return versionOptionsOrdering(versionA.label) - versionOptionsOrdering(versionB.label);
    }) : [];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "searchCarSection" },
            React.createElement("div", { className: "searchCarHeader" }, "Wyszukaj samoch\u00F3d:"),
            React.createElement(HorizontalLine, { color: "#D5D5D5" }),
            React.createElement("div", { className: "stockCarSearchWrapper" },
                React.createElement("div", { className: "stockCarsSearchHeading", "data-testid": 'stock-type-radio-buttons-wrapper' },
                    React.createElement(RadioGroup.Root, { value: searchingType, onValueChange: handleChangeSearchingType },
                        React.createElement("div", { className: "stockCarTypeSearch" },
                            React.createElement("div", { className: "stockCarTypeItem" },
                                React.createElement(RadioGroup.Item, { value: "stock-cars", id: "stock-cars" },
                                    React.createElement(RadioGroup.Indicator, null)),
                                React.createElement(Label, { htmlFor: "stock-cars" }, "Stock")),
                            React.createElement("div", { className: "stockCarTypeItem" },
                                React.createElement(RadioGroup.Item, { value: "sold-cars", id: "sold-cars" },
                                    React.createElement(RadioGroup.Indicator, null)),
                                React.createElement(Label, { htmlFor: "sold-cars" }, "Sprzedane"))))),
                React.createElement("div", { className: "stockCarSearch" },
                    React.createElement(AsyncSelect, { value: null, getOptionLabel: function (e) { return generateStockCarOptionLabel(e, searchingType); }, loadOptions: loadOptions, onChange: stockCarSearchChangeHandler, autoFocus: false, placeholder: 'DO, CON, VIN, Numer rejestracyjny', noOptionsMessage: function () { return null; }, styles: {
                            input: function (provided) { return (__assign(__assign({}, provided), { input: {
                                    gridArea: '1 / 2 / 4 / 4 !important',
                                    padding: '4px 8px !important',
                                } })); },
                            valueContainer: function (provided) { return (__assign(__assign({}, provided), { padding: '0' })); },
                            placeholder: function (provided) { return (__assign(__assign({}, provided), { padding: '4px 8px !important' })); },
                        } }))),
            searchingType === 'stock-cars' && !isModelSelected ? (React.createElement("div", { className: "stockCarInfoWrapper" },
                React.createElement(InfoIcon, null),
                React.createElement("p", { className: "stockCarInfo" }, "Wybierz model pojazdu, aby aktywowa\u0107 pozosta\u0142e filtry."))) : null,
            searchingType === 'stock-cars' ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "stockCarFiltersWrapper", "data-testid": 'stock-cars-filters-wrapper' },
                    React.createElement("div", { className: "stockCarFilter", "data-testid": 'model-filter-wrapper' },
                        React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Model:"),
                        React.createElement(Dropdown, { options: filtersOptions ? filtersOptions['model'] : [], onChange: onModelChange, keyValue: selectedFilters ? selectedFilters['model'].value.toString() : undefined, name: "model", placeholder: "Wybierz", value: selectedFilters && selectedFilters['model'] ? selectedFilters['model'] : undefined, disabled: !filtersOptions || !filtersOptions['model'] })),
                    React.createElement("div", { className: "stockCarFilter", "data-testid": 'model-year-filter-wrapper' },
                        React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Rok modelowy:"),
                        React.createElement(MultiDropdownWithoutChips, { disabled: !isModelSelected, options: sortedModelYearsOptions, name: "modelYears", onChange: function (values) { return onFilterChange('modelYears', values); }, placeholder: "Wybierz", value: selectedFilters && selectedFilters['modelYears'] ? selectedFilters['modelYears'] : [] })),
                    React.createElement("div", { className: "stockCarFilter", "data-testid": 'drive-filter-wrapper' },
                        React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Typ nap\u0119du:"),
                        React.createElement(MultiDropdownWithoutChips, { disabled: !isModelSelected, options: filtersOptions ? filtersOptions['drives'] : [], name: "drives", onChange: function (values) { return onFilterChange('drives', values); }, placeholder: "Wybierz", value: selectedFilters && selectedFilters['drives'] ? selectedFilters['drives'] : [] })),
                    React.createElement("div", { className: "stockCarFilter", "data-testid": 'engine-filter-wrapper' },
                        React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Silnik:"),
                        React.createElement(MultiDropdownWithoutChips, { disabled: !isModelSelected, options: sortedEngineOptions, name: "engines", onChange: function (values) { return onFilterChange('engines', values); }, placeholder: "Wybierz", value: selectedFilters && selectedFilters['engines'] ? selectedFilters['engines'] : [] })),
                    React.createElement("div", { className: "stockCarFilter", "data-testid": 'type-filter-wrapper' },
                        React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Typ zam\u00F3wienia:"),
                        React.createElement(MultiDropdownWithoutChips, { disabled: !isModelSelected, options: filtersOptions ? filtersOptions['types'] : [], name: "types", onChange: function (values) { return onFilterChange('types', values); }, placeholder: "Wybierz", value: selectedFilters && selectedFilters['types'] ? selectedFilters['types'] : [] })),
                    React.createElement("div", { className: "stockCarFilter", "data-testid": 'car-location-filter-wrapper' },
                        React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Miejsce przebywania auta:"),
                        React.createElement(MultiDropdownWithoutChips, { disabled: !isModelSelected, options: filtersOptions ? filtersOptions['carLocations'] : [], name: "carLocations", onChange: function (values) { return onFilterChange('carLocations', values); }, placeholder: "Wybierz", value: selectedFilters && selectedFilters['carLocations'] ? selectedFilters['carLocations'] : [] })),
                    React.createElement("div", { className: "stockCarFilter", "data-testid": 'version-filter-wrapper' },
                        React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Wersja:"),
                        React.createElement(MultiDropdownWithoutChips, { disabled: !isModelSelected, options: sortedVersionOptions, name: "versions", onChange: function (values) { return onFilterChange('versions', values); }, placeholder: "Wybierz", value: selectedFilters && selectedFilters['versions'] ? selectedFilters['versions'] : [] })),
                    React.createElement("div", { className: "stockCarFilter", "data-testid": 'color-filter-wrapper' },
                        React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Kolor:"),
                        React.createElement(MultiDropdownWithoutChips, { disabled: !isModelSelected, options: filtersOptions ? filtersOptions['colors'] : [], name: "colors", onChange: function (values) { return onFilterChange('colors', values); }, placeholder: "Wybierz", value: selectedFilters && selectedFilters['colors'] ? selectedFilters['colors'] : [] })),
                    React.createElement("div", { className: "stockCarFilter", "data-testid": 'upholstery-filter-wrapper' },
                        React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Kolor tapicerki:"),
                        React.createElement(MultiDropdownWithoutChips, { disabled: !isModelSelected, options: filtersOptions ? filtersOptions['upholsteries'] : [], name: "upholsteries", onChange: function (values) { return onFilterChange('upholsteries', values); }, placeholder: "Wybierz", value: selectedFilters && selectedFilters['upholsteries'] ? selectedFilters['upholsteries'] : [] })),
                    React.createElement("div", { className: "stockCarFilter", "data-testid": 'price-range-filter-wrapper' },
                        React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Cena:"),
                        React.createElement(MultiDropdownWithoutChips, { disabled: !isModelSelected, options: filtersOptions ? filtersOptions['priceRanges'] : [], name: "priceRanges", onChange: function (values) { return onFilterChange('priceRanges', values); }, placeholder: "Wybierz", value: selectedFilters && selectedFilters['priceRanges'] ? selectedFilters['priceRanges'] : [] })),
                    React.createElement("div", { className: "stockCarFilter", "data-testid": 'last-date-for-order-change-filter-wrapper' },
                        React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Data zamro\u017Cenia specyfikacji:"),
                        React.createElement(DateRangePicker, { dates: selectedFilters && selectedFilters.lastDateForOrderChange ? selectedFilters.lastDateForOrderChange : [null, null], onDateRangeChange: function (dates) { return onDateRangeChange('lastDateForOrderChange', dates); }, disabled: !isModelSelected, filterWeekend: false })),
                    React.createElement("div", { className: "stockCarFilter", "data-testid": 'delivery-date-filter-wrapper' },
                        React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Data dostawy:"),
                        React.createElement(DateRangePicker, { dates: selectedFilters && selectedFilters.deliveryDate ? selectedFilters.deliveryDate : [null, null], onDateRangeChange: function (dates) { return onDateRangeChange('deliveryDate', dates); }, disabled: !isModelSelected, filterWeekend: false })),
                    React.createElement("div", { className: "stockCarFilter", "data-testid": 'stock-type-filter-wrapper' },
                        React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Stock:"),
                        React.createElement(Dropdown, { disabled: !isModelSelected, options: filtersOptions ? filtersOptions['stockType'] : [], onChange: function (_, value) { return onFilterChange('stockType', value); }, keyValue: selectedFilters && selectedFilters['stockType'] ? selectedFilters['stockType'].value.toString() : undefined, name: "stockType", placeholder: "Wybierz", value: selectedFilters ? selectedFilters['stockType'] : undefined }))),
                React.createElement(HorizontalLine, { color: "#D5D5D5" }),
                React.createElement("div", { className: "filteringActionsWrapper" },
                    React.createElement("div", { className: "filtersSelectedWrapper" },
                        React.createElement("div", { className: "stockCarLabel" }, "Wybrane opcje:"),
                        React.createElement("div", { className: "filtersChips" }, selectedOptions
                            ? selectedOptions.map(function (filterOptions) {
                                var filterOptionKey = filterOptions[0];
                                var filterOptionValue = filterOptions[1];
                                // @ts-ignore
                                var isMultiSelectOption = Array.isArray(filterOptionValue) && !dateRangeFilterKeys.includes(filterOptionKey);
                                if (isMultiSelectOption) {
                                    return filterOptionValue.map(function (filterOption) {
                                        return (React.createElement("div", { className: "filterChips", "data-testid": 'filter-chips', key: filterOption.value },
                                            filterOption.label,
                                            React.createElement("button", { type: "button", className: "removeFilterButton", onClick: function () { return onDeleteFilter(filterOptionKey, filterOption.value); }, onMouseDown: function (e) { return e.stopPropagation(); } },
                                                React.createElement(RemoveIcon, { size: 10 }))));
                                    });
                                }
                                // @ts-ignore
                                if (dateRangeFilterKeys.includes(filterOptionKey)) {
                                    var isDateRangeValue = filterOptionValue[0] instanceof Date && filterOptionValue[1] instanceof Date;
                                    if (!isDateRangeValue) {
                                        return null;
                                    }
                                    return (React.createElement("div", { className: "filterChips", "data-testid": 'filter-chips', key: filterOptionKey },
                                        format(filterOptionValue[0], 'dd.MM.yyyy'),
                                        " - ",
                                        format(filterOptionValue[1], 'dd.MM.yyyy'),
                                        React.createElement("button", { type: "button", className: "removeFilterButton", 
                                            // @ts-ignore
                                            onClick: function () { return onDeleteFilter(filterOptionKey, [null, null]); }, onMouseDown: function (e) { return e.stopPropagation(); } },
                                            React.createElement(RemoveIcon, { size: 10 }))));
                                }
                                return (React.createElement("div", { className: "filterChips", "data-testid": 'filter-chips', key: filterOptionValue.value },
                                    filterOptionValue.label,
                                    React.createElement("button", { type: "button", className: "removeFilterButton", onClick: function () { return onDeleteFilter(filterOptionKey, filterOptionValue.value); }, onMouseDown: function (e) { return e.stopPropagation(); } },
                                        React.createElement(RemoveIcon, { size: 10 }))));
                            })
                            : null)),
                    React.createElement("div", { className: "searchStockCarButtonsWrapper" },
                        React.createElement("div", { className: "searchStockCarButtons" },
                            React.createElement("div", { className: "clearFilters ".concat(clearFiltersDisabled ? 'disabled' : '') },
                                React.createElement("button", { className: 'clearFiltersButton', type: 'button', onClick: clearAllSelectedFilters }, "Wyczy\u015B\u0107 filtry")),
                            React.createElement("div", { className: "searchStockCarButtonWrapper" },
                                React.createElement(Button, { disabled: searchIsDisabled, onClick: onSearchClick }, "wyszukaj"))))),
                React.createElement("div", { className: "carsListHeader" },
                    React.createElement("div", { className: "carsAmount", "data-testid": 'total-cars' },
                        "Liczba samochod\u00F3w (",
                        totalCars,
                        ")"),
                    React.createElement("div", { className: "carsSortingWrapper", "data-testid": 'sorting-wrapper' },
                        React.createElement("div", { className: "stockCarFilterSectionLabel" }, "Sortowanie:"),
                        React.createElement(Dropdown, { options: sortingAvailableOptions, onChange: onSortingChange, name: "sorting", value: seletedSorting, disabled: !isModelSelected }))))) : null),
        React.createElement(CarsListSection, { stockCars: stockCars, handleSetAppError: handleSetAppError, totalPages: totalPages, currentPage: currentPage, changePageHandler: changePageHandler, noMatchedCars: noMatchedCars })));
}
