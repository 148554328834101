export function concessionApplicationChangeName(status) {
    switch (status) {
        case 'APPLICATION_SUBMITTED':
            return 'Wniosek wysłany';
        case 'APPLICATION_RETURNED':
            return 'Wniosek do uzupełnienia';
        case 'APPLICATION_REJECTED':
            return 'Wniosek odrzucony';
        case 'APPLICATION_ACCEPTED':
            return 'Wniosek zaakceptowany';
        case 'SUPPLEMENT_ADDED':
            return 'Wniosek o rozszerzenie wysłany';
        case 'SUPPLEMENT_ACCEPTED':
            return 'Wniosek o rozszerzenie zaakceptowany';
        case 'SUPPLEMENT_REJECTED':
            return 'Wniosek o rozszerzenie odrzucony';
        case 'SETTLEMENT_ADDED':
            return 'Zamówienie do rozliczenia';
        case 'SETTLEMENTS_SETTLED':
            return 'Zamówienia rozliczone';
        case 'SETTLEMENTS_REJECTED':
            return 'Zamówienia odrzucone';
        case 'SETTLEMENT_WITHDRAWN':
            return 'Zamówienie wycofane';
        case 'SETTLEMENT_CORRECTION_APPLICATION_SUBMITTED':
            return 'Rozliczenie do skorygowania';
        case 'SETTLEMENT_CORRECTION_APPLICATION_ACCEPTED':
            return 'Rozliczenie skorygowane';
        case 'SETTLEMENT_CORRECTION_APPLICATION_REJECTED':
            return 'Korekta rozliczenia odrzucona';
        case 'VALIDITY_PERIOD_CHANGED':
            return 'Termin ważności zmieniony';
        default:
            return status;
    }
}
