import React from 'react';
import { formatDate } from 'utils/formatDate';
import { immutableSort } from 'utils/immutableSort';
import './changesHistory.css';
import { concessionApplicationChangeName } from '../concessionApplicationChangeName';
export function ChangesHistory(_a) {
    var changesHistory = _a.changesHistory;
    var historyData = changesHistory;
    var sortedHistoryData = immutableSort(historyData, function (a, b) {
        var dateA = new Date(a.timestamp).getTime();
        var dateB = new Date(b.timestamp).getTime();
        return dateA - dateB;
    });
    function getDate(timestamp) {
        var date = timestamp.split(' ')[0];
        var formattedDate = formatDate(new Date(date));
        return formattedDate;
    }
    function getTime(timestamp) {
        var time = timestamp.split(' ')[1];
        var _a = time.split(':'), hour = _a[0], minutes = _a[1], seconds = _a[2];
        var formattedTime = hour + ':' + minutes;
        return formattedTime;
    }
    return React.createElement("div", { className: 'vcpDiscountChangesHistoryWrapper', "data-testid": 'changes-history' },
        React.createElement("div", { className: 'vcpDiscountChangesHistoryHeader' }, "historia zmian"),
        React.createElement("div", { className: 'vcpDiscountchangesHistoryTableWrapper', "data-testid": 'vcp-discount-changes-history-table-wrapper' },
            React.createElement("table", { className: 'vcpDiscountChangesHistoryTable' },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: 'vcpDiscountChangesHistoryTableHeadCell vcpDiscountChangesHistoryTableDateCell' }, "Data i godzina"),
                        React.createElement("th", { className: 'vcpDiscountChangesHistoryTableHeadCell vcpDiscountChangesHistoryTableStatusCell', style: { paddingRight: '12px' } }, "Status"),
                        React.createElement("th", { className: 'vcpDiscountChangesHistoryTableHeadCell vcpDiscountChangesHistoryTableCommentCell' }, "Komentarz"),
                        React.createElement("th", { className: 'vcpDiscountChangesHistoryTableHeadCell vcpDiscountChangesHistoryTableAuthorCell' }, "Wprowadzaj\u0105cy"))),
                React.createElement("tbody", null, sortedHistoryData.map(function (statusChange, index) { return (React.createElement("tr", { className: 'vcpDiscountChangesHistoryTableRow', key: index },
                    React.createElement("td", { className: 'vcpDiscountChangesHistoryTableBodyCell vcpDiscountChangesHistoryTableDateCell' },
                        React.createElement("div", { className: 'vcpDiscountChangesHistoryDateAndTimeWrapper' }, statusChange.timestamp ?
                            React.createElement(React.Fragment, null,
                                React.createElement("div", null, getDate(statusChange.timestamp)),
                                React.createElement("div", null, getTime(statusChange.timestamp)))
                            : null)),
                    React.createElement("td", { className: 'vcpDiscountChangesHistoryTableBodyCell vcpDiscountChangesHistoryTableStatusCell' }, concessionApplicationChangeName(statusChange.change)),
                    React.createElement("td", { className: 'vcpDiscountChangesHistoryTableBodyCell vcpDiscountChangesHistoryTableCommentCell' }, statusChange.comment),
                    React.createElement("td", { className: 'vcpDiscountChangesHistoryTableBodyCell vcpDiscountChangesHistoryTableAuthorCell' }, statusChange.applier))); })))));
}
