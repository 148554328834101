import { useHttp } from 'http/httpService';
export function useConcessionApi() {
    var http = useHttp();
    return {
        getConcession: function (concessionId) {
            return http
                .get("/api/concessions/".concat(concessionId))
                .then(function (response) { return response.data; });
        },
        loadLocation: function (location) {
            return http
                .get('/api' + location, { responseType: 'blob' });
        },
        getPartyCars: function (identificationNumber, from, to) {
            return http
                .get("/api/cars?byPartyIdentificationNumber=".concat(identificationNumber, "&from=").concat(from, "&to=").concat(to))
                .then(function (response) { return response.data; });
        },
        getOrganizationData: function (organizationId) {
            return http
                .get("/api/organizations/".concat(organizationId))
                .then(function (response) { return response.data; });
        },
        getUserCardData: function (userId) {
            return http
                .get("/api/users/".concat(userId, "?card"))
                .then(function (response) { return response.data; });
        },
        getAuditDocuments: function (orderId) {
            return http
                .get("/api/concession-settlement-documentations/".concat(orderId))
                .then(function (response) { return response; });
        },
        returnAuditDocuments: function (settlementId) {
            return http.post("/api/concession-settlement-documentations/".concat(settlementId, "/return")).then(function (response) { return response; });
        }
    };
}
