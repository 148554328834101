import { useHttp } from 'http/httpService';
var mockPartyCars = [
    {
        modelId: '246',
        modelYear: 2025,
        dealerOrder: '9999999',
        commonOrderNumber: '10916786',
        vin: 'YV1XZK7V3R2215899',
        registrationNumber: null
    },
    {
        modelId: '235',
        modelYear: 2025,
        dealerOrder: '8888888',
        commonOrderNumber: '10939999',
        vin: 'YV1XZK7V3R2215811',
        registrationNumber: 'XYZ12345'
    },
    {
        modelId: '536',
        modelYear: 2025,
        dealerOrder: '7777777',
        commonOrderNumber: '10935555',
        vin: 'YV1XZK7V3R2215822',
        registrationNumber: 'ABC98765'
    },
    {
        modelId: '137',
        modelYear: 2025,
        dealerOrder: '6666666',
        commonOrderNumber: '10934444',
        vin: null,
        registrationNumber: null
    }
];
var mockSelectSalesmen = [
    {
        login: 'k.puchatek',
        name: 'Kubuś Puchatek'
    },
    {
        login: 'a.makota',
        name: 'Ala Makota'
    },
    {
        login: 'm.muminka',
        name: 'Mama Muminka'
    }
];
var mockTradeIn = {
    applicant: {
        applicationDate: '2024-12-10',
        organizationName: 'DOM VOLVO Warszawa Puławska',
        organizationId: 'PL301',
        assistantId: 'a.kropek',
        assistantName: 'Agata Kropek',
        applicantId: 'p.select',
        applicantName: 'Pan Select'
    },
    ordering: {
        party: {
            id: '810a5dcb-b336-4c4c-a691-3a1b9eb5b101',
            type: 'INDIVIDUAL',
            name: 'testy testowe',
            identificationNumber: {
                type: 'PESEL',
                value: '97111397213'
            },
            address: {
                city: '',
                postalCode: '',
                street: '',
                country: null
            },
            dolName: 'testy testowe',
            dolId: '18d51957-cf55-459c-96ac-f2138c53f9ea%232',
        },
        car: {
            commonOrderNumber: '10916786',
            dealerOrder: '9999999',
            modelId: '246',
            modelYear: 2025,
            registrationNumber: 'ABC12345',
            vin: 'YV1XZK7V3R2215899',
            orderId: 'abc123def456'
        }
    },
    tradeInDiscount: {
        vin: 'VIN1234567890111',
        firstRegistrationDate: '2022-12-10',
        repurchaseDate: '2024-12-05',
        mileage: 120000.56,
        selectSalesman: {
            login: 'k.puchatek',
            name: 'Kubuś Puchatek'
        },
        carPurchaseDocument: [
            {
                fileName: 'Volvo_OFR_N_2412_0002.pdf',
                fileUrl: '/files/c8406186-f347-4997-b8df-7e18b5e9247e'
            }
        ],
        registrationCertificateCopy: [
            {
                fileName: 'Volvo_OFR_N_2412_0002.pdf',
                fileUrl: '/files/c8406186-f347-4997-b8df-7e18b5e9247e'
            }
        ],
        newCarPurchaseDocument: [
            {
                fileName: 'Volvo_OFR_N_2412_0002.pdf',
                fileUrl: '/files/c8406186-f347-4997-b8df-7e18b5e9247e'
            }
        ],
        handoverProtocol: [
            {
                fileName: 'Volvo_OFR_N_2412_0002.pdf',
                fileUrl: '/files/c8406186-f347-4997-b8df-7e18b5e9247e'
            }
        ],
        consentDocument: [
            {
                fileName: 'Volvo_OFR_N_2412_0002.pdf',
                fileUrl: '/files/c8406186-f347-4997-b8df-7e18b5e9247e'
            }
        ],
        otherDocuments: [
            {
                fileName: 'Volvo_OFR_N_2412_0002.pdf',
                fileUrl: '/files/c8406186-f347-4997-b8df-7e18b5e9247e'
            }
        ],
        supportVcpAmountNet: 10000,
        statuses: [
            {
                eventDate: '2024-12-10 07:55:53',
                status: 'SUBMITTED',
                additionalInfo: 'Test wysłania',
                applicantId: 'a.kropek',
                id: 'abc123def456'
            },
            {
                eventDate: '2024-12-11 08:30:00',
                status: 'APPROVED_RECKONING',
                additionalInfo: 'Roliczenie komentarz',
                applicantId: 'p.zvcp',
                id: 'xyz987abc456'
            }
        ]
    },
    settlements: [
        {
            dealerName: 'DOM VOLVO Warszawa Puławska',
            car: {
                commonOrderNumber: 10916786,
                modelId: '246_2025'
            },
            discountNet: 10000,
            status: 'SETTLED'
        }
    ]
};
var vcpDiscountSettlementMock = {
    'correctionApplication': null,
    'orderId': 'c68de43e-3f15-456d-b0c1-afd7411b4b1b',
    'orderDate': '2024-12-04',
    'dealerOrder': null,
    'vin': null,
    'status': 'CORRECTION_APPLICATION_SUBMITTED',
    'listPrice': 241991.87,
    'finalPrice': 235894.31,
    'producerPercentDiscount': 1,
    'funding': {
        'fundingForm': {
            id: 'CFM_FUNDING',
            name: 'Finansowanie CFM'
        },
        'cfmProvider': {
            id: '11',
            name: 'Nivette Fleet Management'
        },
        'leasingProvider': {
            'id': null,
            'name': null
        }
    },
    'discount': {
        'discountGroup': {
            id: 'PROFESSION_GROUP',
            name: 'Grupa Zawodowa'
        },
        'professionGroup': {
            id: 'LAWYERS',
            name: 'prawnicy (adwokaci, komornicy, notariusze, radcy prawni, sędziowie)'
        },
        'specialOfferType': {
            'id': null,
            'name': null,
            'archive': false
        },
        'specialOfferGlobalMajorAccount': {
            'id': null,
            'name': null
        },
        'secondDiscountGroupGlobalMajorAccount': {
            'id': null,
            'name': null
        }
    },
    'declaredDiscountGroup': null,
    'declaredFunding': null,
    'modelId': null,
    'fuelType': null,
    'clientDiscountNet': 6097.56,
    'vcpDiscountNet': 2419.92
};
var drsDiscountsForPartyMock = [
    {
        'id': 'da3d6d29-21e9-477c-8ceb-e7b65282dd4c',
        'dealerName': 'DOM VOLVO Warszawa Puławska',
        'status': 'RETURNED',
        'requestedNetDiscount': 3000,
        'car': {
            'commonOrderNumber': '11115115',
            'modelId': '246_2025',
            'vin': 'YV1UZH4V8S1033461'
        }
    },
    {
        'id': 'ad266b70-c081-45a9-aac3-c547b8839236',
        'dealerName': 'VOLVO CAR PGD Kraków Powstańców Śląskich',
        'status': 'RETURNED',
        'requestedNetDiscount': 2000,
        'car': {
            'commonOrderNumber': '10916786',
            'modelId': '236_2025',
            'vin': 'YV1UZH1V3P1331430'
        }
    }
];
export function useTradeInApi() {
    var http = useHttp();
    return {
        getApplicantData: function () {
            return http
                .get('/api/users/me')
                .then(function (response) { return response.data; });
        },
        getUserData: function (login) {
            return http
                .get("/api/users/".concat(login, "?card"))
                .then(function (response) { return response.data; });
        },
        getPartiesWithIdentyficationNumber: function (clientId) {
            return http
                .get("/api/clients/".concat(clientId, "/parties?withIdentificationNumber"))
                .then(function (response) { return response.data; });
        },
        getParty: function (partyId) {
            return http
                .get("/api/parties/".concat(partyId))
                .then(function (response) { return response.data; });
        },
        getOrdersByParty: function (partyId) {
            return http.get("/api/orders?ofParty=".concat(partyId))
                .then(function (response) { return response.data; });
        },
        getSelectSalesmen: function () {
            return http
                .get('/api/users?withRole=USED_CAR_SALES')
                .then(function (response) { return response.data; });
        },
        addFile: function (file) {
            var formData = new FormData();
            formData.append('file', file);
            return http
                .post('/api/files', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(function (response) {
                return {
                    fileUrl: response.headers.location
                };
            });
        },
        deleteFile: function (fileLocation) {
            return http.delete("/api".concat(fileLocation));
        },
        loadLocation: function (location) {
            return http
                .get('/api' + location, { responseType: 'blob' });
        },
        saveTradeId: function (tradeInData) {
            return http
                .post('/api/trade-in', tradeInData).then(function (response) { return response; });
        },
        getTradeIn: function (tradeInId) {
            return http
                .get("/api/trade-in/".concat(tradeInId)).then(function (response) { return mockTradeIn; });
        },
        getVcpDiscountSettlementOfOrder: function (orderId) {
            return http.get("/api/concessions/settlements?ofOrder=".concat(orderId))
                .then(function (response) { return response.data; });
            // .then(response => vcpDiscountSettlementMock);
        },
        getDrsDiscountsForIdentificationNumber: function (identificationNumber) {
            return http.get("/api/extra-sale-discounts?forIdentificationNumber=".concat(identificationNumber))
                .then(function (response) { return response.data; });
            // .then(response => drsDiscountsForPartyMock);
        }
    };
}
