import React, { useEffect, useRef } from 'react';
import { StockCarsListHeadRow } from './StockCarsListHeadRow';
import { PaginatedStockCars } from './PaginatedStockCars.component';
import '../newOfferSections.css';
export function CarsListSection(_a) {
    var stockCars = _a.stockCars, handleSetAppError = _a.handleSetAppError, totalPages = _a.totalPages, currentPage = _a.currentPage, changePageHandler = _a.changePageHandler, noMatchedCars = _a.noMatchedCars;
    var carListWrapperELement = useRef(null);
    useEffect(function () {
        if (carListWrapperELement.current && stockCars) {
            carListWrapperELement.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [stockCars]);
    return (React.createElement("div", { className: 'carsListSectionWrapper', ref: carListWrapperELement }, stockCars && stockCars.length > 0 ? (React.createElement("div", { className: "stockCarsListContainer", "data-testid": 'stock-cars-list-wrapper' },
        React.createElement(StockCarsListHeadRow, null),
        stockCars ? (React.createElement(PaginatedStockCars, { stockCars: stockCars, totalPages: totalPages, changePageHandler: changePageHandler, currentPage: currentPage, handleSetAppError: handleSetAppError })) : null)) : (stockCars && stockCars.length === 0) || noMatchedCars ? (React.createElement("div", { className: "stockCarsListContainer" },
        React.createElement("div", { className: 'stockCarsMessageWrapper' },
            React.createElement("h2", { className: 'stockCarsMessageHeading' }, "Brak aut spe\u0142niaj\u0105cych te wymagania.")))) : null));
}
