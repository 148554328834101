// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .supplementSectionWrapper {
    padding: 32px 0;
    font-family: var(--font-family);
    color: var(--black-text-color);
    margin: 8px 0;
}

#new-dol .supplementsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

#new-dol .supplementContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    padding: 8px;
    border: 1px solid #D5D5D5;;
}

#new-dol .supplementSectionLabel {
    padding-left: 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--black-text-color);
    margin-bottom: 8px;
}

#new-dol .supplementSectionValue {
    color: var(--black-text-color);
    padding-left: 16px;
    font-size: 14px;
    line-height: 20px;
}

#new-dol .supplementSectionAcceptanceButton {
    width: 240px;
    justify-self: end;
}

#new-dol .supplementSection {
    margin-bottom: 24px;
}

#new-dol .supplementSection:last-child {
    margin-bottom: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/concessions/supplementSection/supplementSection.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,+BAA+B;IAC/B,8BAA8B;IAC9B,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".supplementSectionWrapper {\n    padding: 32px 0;\n    font-family: var(--font-family);\n    color: var(--black-text-color);\n    margin: 8px 0;\n}\n\n.supplementsContainer {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}\n\n.supplementContainer {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 32px;\n    padding: 8px;\n    border: 1px solid #D5D5D5;;\n}\n\n.supplementSectionLabel {\n    padding-left: 16px;\n    font-size: 14px;\n    line-height: 20px;\n    font-weight: 500;\n    color: var(--black-text-color);\n    margin-bottom: 8px;\n}\n\n.supplementSectionValue {\n    color: var(--black-text-color);\n    padding-left: 16px;\n    font-size: 14px;\n    line-height: 20px;\n}\n\n.supplementSectionAcceptanceButton {\n    width: 240px;\n    justify-self: end;\n}\n\n.supplementSection {\n    margin-bottom: 24px;\n}\n\n.supplementSection:last-child {\n    margin-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
