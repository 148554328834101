import React from 'react';
import { useLocation } from 'react-router-dom';
import { SpecialOfferSearch } from '../specialOfferSearch/SpecialOfferSearch';
import { SpecialOfferPreview } from './SpecialOfferPreview';
import { ExtraSaleDiscountPreview } from 'pages/extraSaleDiscounts/extraSaleDiscountPreview/ExtraSaleDiscountPreview';
import { ConcessionPreview } from 'pages/concessions/concessionPreview/ConcessionPreview';
export function SpecialOfferSearchPreview() {
    var location = useLocation();
    var _a = location.search.split('?'), searchParamsString = _a[1];
    var searchParams = new URLSearchParams(searchParamsString);
    var specialOfferNumber = searchParams.get('specialOfferNumber');
    var extraSaleDiscountId = searchParams.get('extraSaleDiscountId');
    var concessionId = searchParams.get('concessionId');
    return (React.createElement("div", null,
        React.createElement("div", null,
            React.createElement(SpecialOfferSearch, null)),
        React.createElement("div", null,
            specialOfferNumber && React.createElement(SpecialOfferPreview, { key: specialOfferNumber }),
            extraSaleDiscountId && React.createElement(ExtraSaleDiscountPreview, { key: specialOfferNumber }),
            concessionId && React.createElement(ConcessionPreview, { key: concessionId }))));
}
