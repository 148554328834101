import React from 'react';
import '../settlementSection/settlementSection.css';
import ModelDictionary from 'common/modelDictionary';
import { vcpDiscountName } from 'common/vcpDiscountName';
import { TextAmount } from 'components/textAmount/TextAmount';
import { fundingName } from 'common/fundingName';
import { settlementStatus } from 'common/vcpSettlementStatus';
import { Button } from 'components/button/Button.component';
export function SettlementCorrectionsSection(_a) {
    var settlementCorrectionsSectionData = _a.settlementCorrectionsSectionData, isVCPSales = _a.isVCPSales, concessionId = _a.concessionId;
    function onSettlementCorrectionApplicationAcceptanceClick(orderId) {
        window.location.href = "#/concessions/settlement-correction-application-acceptance/".concat(concessionId, "/").concat(orderId, "?fromNewSearch");
    }
    return (settlementCorrectionsSectionData ?
        React.createElement("div", { className: 'settlementSectionWrapper settlementsCorectionsContainer', "data-testid": 'settlement-corrections-section-table-wrapper' }, settlementCorrectionsSectionData.map(function (settlementCorrection, index) { return (React.createElement("div", { key: index, className: 'settlementCorrectionWrapper' },
            React.createElement("table", { className: "settlementSectionTable" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementOrderDateCell', rowSpan: 2 }, "Data zam\u00F3wienia"),
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementDOVINCell', rowSpan: 2 },
                            "Dealer Order",
                            React.createElement("br", null),
                            "VIN"),
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementCarCell', rowSpan: 2 },
                            "Model",
                            React.createElement("br", null),
                            "Typ silnika"),
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementDiscountGroupCell', rowSpan: 2 },
                            "Oferta specjalna / Grupa rabatowa VCP",
                            React.createElement("br", null),
                            "Deklarowana w Vista"),
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementCatalogPriceCell', rowSpan: 2 }, "Cena katalogowa netto"),
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementClientDiscountNet', rowSpan: 2 }, "Rabat klienta netto"),
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementHeader', colSpan: 2 }, "Rabat VCP"),
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementFunding', rowSpan: 2 },
                            "Finansowanie",
                            React.createElement("br", null),
                            "Deklarowane w Vista"),
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementStatus', rowSpan: 2 }, "Status")),
                    React.createElement("tr", null,
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementClientDiscountPercent' }, "%"),
                        React.createElement("th", { className: 'settlementSectionHeadCell settlementAmountNet' }, "kwota netto"))),
                React.createElement("tbody", null,
                    React.createElement("tr", { className: 'settlementSectionTableRow' },
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementOrderDateCell' }, settlementCorrection.orderDate),
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementDOVINCell' },
                            settlementCorrection.dealerOrder,
                            React.createElement("br", null),
                            settlementCorrection.vin),
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementCarCell' },
                            ModelDictionary.nameFor(settlementCorrection.modelId.split('_')[0]),
                            React.createElement("br", null),
                            settlementCorrection.fuelType,
                            React.createElement("br", null),
                            "(",
                            settlementCorrection.modelId.split('_')[1],
                            ")"),
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementDiscountGroupCell' }, vcpDiscountName(settlementCorrection.discount)),
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementCatalogPriceCell' },
                            React.createElement(TextAmount, { textAlignEnd: false }, settlementCorrection.listPrice)),
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementClientDiscountNet' },
                            React.createElement(TextAmount, { textAlignEnd: false }, settlementCorrection.clientDiscountNet)),
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementClientDiscountPercent' },
                            settlementCorrection.producerPercentDiscount,
                            " %"),
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementAmountNet' },
                            React.createElement(TextAmount, { textAlignEnd: false }, settlementCorrection.vcpDiscountNet)),
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementFunding' },
                            fundingName(settlementCorrection.funding),
                            React.createElement("br", null),
                            settlementCorrection.declaredFunding),
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementStatus' }, settlementStatus(settlementCorrection.status))),
                    React.createElement("tr", { className: 'settlementSectionTableRow' },
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementOrderDateCell' }),
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementDOVINCell' }),
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementCarCell' }),
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementDiscountGroupCell' }, vcpDiscountName(settlementCorrection.correctionApplication.discount)),
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementCatalogPriceCell' },
                            React.createElement(TextAmount, { textAlignEnd: false }, settlementCorrection.correctionApplication.listPrice)),
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementClientDiscountNet' },
                            React.createElement(TextAmount, { textAlignEnd: false }, settlementCorrection.correctionApplication.clientDiscountNet)),
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementClientDiscountPercent' },
                            settlementCorrection.correctionApplication.producerPercentDiscount,
                            " %"),
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementAmountNet' },
                            React.createElement(TextAmount, { textAlignEnd: false }, settlementCorrection.correctionApplication.vcpDiscountNet)),
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementFunding' }),
                        React.createElement("td", { className: 'settlementSectionBodyCell settlementStatus' })))),
            isVCPSales &&
                React.createElement(React.Fragment, null,
                    React.createElement("div", null),
                    React.createElement("div", { className: 'supplementSectionAcceptanceButton' },
                        React.createElement(Button, { variant: 'secondary', type: "button", onClick: function () { return onSettlementCorrectionApplicationAcceptanceClick(settlementCorrection.orderId); } }, "przejd\u017A do akceptacji"))))); }))
        : null);
}
