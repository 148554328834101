import React from 'react';
import ModelDictionary from 'common/modelDictionary';
import './orderingPartySection.css';
import { formatDate } from 'utils/formatDate';
import { clientPartyTypeDescription } from 'common/clientPartyTypeDescription';
import { List } from 'components/list/List.component';
import { openFile } from 'common/openFile';
import { useConcessionApi } from '../useConcessionApi';
export function OrderingPartyPreviewSection(_a) {
    var orderingPartyPreviewData = _a.orderingPartyPreviewData;
    var concessionApi = useConcessionApi();
    var cars = (orderingPartyPreviewData === null || orderingPartyPreviewData === void 0 ? void 0 : orderingPartyPreviewData.partyCars)
        ? orderingPartyPreviewData.partyCars.map(function (car) {
            var modelCode = car.modelId.split('_')[0];
            return {
                handoverDate: car.handoverDate,
                vin: car.vin,
                carModel: ModelDictionary.nameFor(modelCode),
                modelYear: car.modelId.split('_')[1],
            };
        })
        : [];
    function handleFileNameClick(fileUrl, fileName) {
        concessionApi.loadLocation(fileUrl).then(function (response) { return openFile(response, fileName); }).catch(function () { });
        ;
    }
    return (orderingPartyPreviewData &&
        React.createElement("div", { className: 'orderingPartySectionWrapper', "data-testid": 'ordering-party-section' },
            React.createElement("div", { className: 'orderingPartySectionGridWrapper' },
                React.createElement("div", { className: 'orderingPartySectionGridColumn' },
                    React.createElement("div", null,
                        React.createElement("div", { className: "orderingPartyLabel" }, "Strona klienta:"),
                        (orderingPartyPreviewData === null || orderingPartyPreviewData === void 0 ? void 0 : orderingPartyPreviewData.party) ?
                            React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "orderingPartySectionValue", "data-testid": "ordering-party-section-party-type" }, clientPartyTypeDescription(orderingPartyPreviewData.party.type)),
                                React.createElement("div", { className: "orderingPartySectionValue", "data-testid": "ordering-party-section-party-value" },
                                    orderingPartyPreviewData.party.name,
                                    " ",
                                    orderingPartyPreviewData.party.identificationNumber.type,
                                    ": ",
                                    orderingPartyPreviewData.party.identificationNumber.value))
                            : null),
                    React.createElement("div", null,
                        React.createElement("div", { className: "orderingPartyLabel" }, "Adres strony:"),
                        (orderingPartyPreviewData === null || orderingPartyPreviewData === void 0 ? void 0 : orderingPartyPreviewData.party) ?
                            React.createElement("div", { className: "orderingPartySectionValue", "data-testid": "ordering-party-section-party-address" },
                                React.createElement("div", null, orderingPartyPreviewData.party.address.street),
                                React.createElement("div", null,
                                    orderingPartyPreviewData.party.address.postalCode,
                                    orderingPartyPreviewData.party.address.postalCode ? ',' : '',
                                    " ",
                                    orderingPartyPreviewData.party.address.city))
                            : null),
                    React.createElement("div", null,
                        React.createElement("div", { className: "orderingPartyLabel" }, "Data zapytania:"),
                        React.createElement("div", { className: "orderingPartySectionValue", "data-testid": "ordering-party-section-offer-request-date" }, formatDate(new Date(orderingPartyPreviewData.offerRequestDate))))),
                React.createElement("div", { className: 'orderingPartySectionGridColumn' },
                    React.createElement("div", null,
                        React.createElement("div", { className: "orderingPartyLabel" }, "Potwierdzenie zapytania:"),
                        React.createElement("div", null, orderingPartyPreviewData.offerRequestEvidences.length > 0 ? (React.createElement(List.Root, null, orderingPartyPreviewData.offerRequestEvidences.map(function (file) { return (React.createElement(List.Item, { key: file.location },
                            React.createElement("div", { className: "orderingPartySectionAttachmentItem" },
                                React.createElement("div", { onClick: function () { return handleFileNameClick(file.location, file.fileName); }, "data-testid": "ordering-party-section-evidences-attachment" }, file.fileName)))); }))) : null)),
                    React.createElement("div", null,
                        React.createElement("div", { className: "orderingPartyLabel" }, "Samochody:"),
                        React.createElement("div", { className: "orderingPartySectionCarsTableWrapper", "data-testid": 'ordering-party-section-cars-table-wrapper' },
                            React.createElement("table", { className: "orderingPartySectionCarsTable" },
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement("th", { className: "orderingPartySectionCarTableHeader orderingPartySectionCarOrderDateCell" }, "Data wydania"),
                                        React.createElement("th", { className: "orderingPartySectionCarTableHeader" }, "Model"),
                                        React.createElement("th", { className: "orderingPartySectionCarTableHeader orderingPartySectionCarVinCell" }, "VIN"))),
                                React.createElement("tbody", null, cars && cars.length > 0
                                    ? cars.map(function (car) { return (React.createElement("tr", { className: "orderingPartySectionCarTableRow", key: car.vin },
                                        React.createElement("td", { className: "orderingPartySectionCarTableCell orderingPartySectionCarOrderDateCell" }, car.handoverDate ? formatDate(new Date(car.handoverDate)) : ''),
                                        React.createElement("td", { className: "orderingPartySectionCarTableCell" }, "".concat(car.carModel, " (").concat(car.modelYear, ")")),
                                        React.createElement("td", { className: "orderingPartySectionCarTableCell orderingPartySectionCarVinCell" }, car.vin))); })
                                    : null))))))));
}
