var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { Button } from 'components/button/Button.component';
import React, { useEffect, useState } from 'react';
import { CommentTextArea } from '../additionalInfos/AdditionalInfos';
import { useAdditionalInfos } from '../additionalInfos/useAdditionalInfos';
import { ApplicantData } from '../applicantData/ApplicantData';
import { useApplicantData } from '../applicantData/useApplicantData';
import { ClientSection } from '../clientSection/ClientSection';
import { useClientSection } from '../clientSection/useClientSection';
import { HorizontalLine } from '../horizontalLine/HorizontalLine';
import { useRequestSubject } from '../requestSubject/useRequestSubject';
import { SalePotential } from '../salePotential/SalePotential';
import { useSalePotential } from '../salePotential/useSalePotential';
import { useSpecialOfferApi } from '../useSpecialOfferApi';
import { ViewSection } from '../viewSection/ViewSection';
import './newSpecialOfferForm.css';
import ModelDictionary from 'common/modelDictionary';
import { formatShortDate } from 'utils/formatShortDate';
import { useStatus } from 'common/statusContext';
import { Spinner } from 'components/spinner/Spinner';
import { NewRequestSubjectsSection } from '../requestSubject/NewRequestSubjectsSection';
import { Plus } from 'components/accessories/Plus.comonent';
import { PlusDisabled } from 'components/accessories/PlusDisabled.component';
import uuid from 'react-uuid';
import { InfoDialog } from '../InfoDialog';
var MODE = 'CREATE';
var DIALOG_MESSAGE = 'Oferta Specjalna dla tej strony została przyznana.';
export var NewSpecialOffer = withCancellation(function () {
    var specialOfferApi = useSpecialOfferApi();
    var applicantSection = useApplicantData({ mode: MODE });
    var createClientSection = useClientSection();
    var salePotentialSection = useSalePotential();
    var requestSubjectSection = useRequestSubject(createClientSection.idSelectedParty);
    var additionalInfosSection = useAdditionalInfos();
    var _a = useStatus(), status = _a.status, setStatus = _a.setStatus;
    var _b = useState(), tabValue = _b[0], setTabValue = _b[1];
    var _c = useState(false), openConfirmDialog = _c[0], setOpenConfirmDialog = _c[1];
    var _d = useState(''), dialogMessage = _d[0], setDialogMessage = _d[1];
    function onTabChange(value) {
        setTabValue(value);
    }
    useEffect(function () {
        if (createClientSection.idSelectedParty && requestSubjectSection.requestSubjects.length > 0) {
            setTabValue(requestSubjectSection.requestSubjects[requestSubjectSection.requestSubjects.length - 1].id);
        }
    }, [createClientSection.idSelectedParty, requestSubjectSection.requestSubjects.length, requestSubjectSection.requestSubjects[requestSubjectSection.requestSubjects.length - 1]]);
    var _e = window.location.href.split('?'), searchParamsString = _e[1];
    var searchParams = new URLSearchParams(searchParamsString);
    var ownedCars = createClientSection.partyCars
        ? createClientSection.partyCars.map(function (partyCar) {
            return {
                vin: partyCar.vin,
                modelId: partyCar.modelId,
                handoverDate: partyCar.handoverDate,
                // TODO: orderDate do dodania później
                // FIXME: story https://altkomsoftware.atlassian.net/browse/VOLVODOL-3255
                orderDate: null,
            };
        })
        : [];
    var partyFleet = salePotentialSection.fleet.map(function (fleetCar) {
        return {
            mark: fleetCar.mark,
            model: fleetCar.model,
            amount: Number(fleetCar.amount),
        };
    });
    function fundingProviders(requestSubject) {
        return {
            CASH: '',
            LEASING: requestSubject.selectedLeasingProvider && requestSubject.selectedLeasingProvider.value,
            LEASING_OTHER: requestSubject.otherLeasingProvider,
            CFM: requestSubject.selectedCfmProvider && requestSubject.selectedCfmProvider.value,
            CFM_OTHER: requestSubject.otherCfmProvider,
        };
    }
    ;
    function onSaveSpecialOfferClick() {
        return __awaiter(this, void 0, void 0, function () {
            var clientId, specialOffersForPartyResponse, specialOffersForParty, partyCopy, specialOfferData, saveNewSpecialOfferResponse, clientId_1, partyId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        clientId = searchParams.get('clientId');
                        setStatus('pending');
                        return [4 /*yield*/, specialOfferApi.specialOffersForParty(createClientSection.party.identificationNumber.value)];
                    case 1:
                        specialOffersForPartyResponse = _a.sent();
                        specialOffersForParty = specialOffersForPartyResponse.filter(function (specialOffer) { return specialOffer.number; });
                        if (specialOffersForParty.length > 0) {
                            setStatus('success');
                            setDialogMessage(DIALOG_MESSAGE);
                            setOpenConfirmDialog(true);
                            return [2 /*return*/];
                        }
                        partyCopy = __assign({}, createClientSection.party);
                        delete partyCopy.id;
                        specialOfferData = {
                            applicant: {
                                applicationDate: formatShortDate(applicantSection.applicantData.applicationDate),
                                organizationId: applicantSection.applicantData.organizationId,
                                organizationName: applicantSection.applicantData.organizationName,
                                login: applicantSection.applicantData.applicantId,
                                name: applicantSection.applicantData.applicantName,
                            },
                            ordering: {
                                party: __assign(__assign({}, partyCopy), { dominantPKDCode: createClientSection.partyDataFromGusOrKrs && (createClientSection.partyDataFromGusOrKrs.mainPkdCode || createClientSection.partyDataFromGusOrKrs.mainPkdCodeDescription) ? "".concat(createClientSection.partyDataFromGusOrKrs.mainPkdCode, " ").concat(createClientSection.partyDataFromGusOrKrs.mainPkdCodeDescription) : null, name: createClientSection.partyDataFromGusOrKrs && createClientSection.partyDataFromGusOrKrs.name ? createClientSection.partyDataFromGusOrKrs.name : null, dolName: createClientSection.party.name, dolId: encodeURIComponent(createClientSection.party.id) }),
                            },
                            type: {
                                value: createClientSection.selectedSpecialOfferType.value,
                                subType: {
                                    value: createClientSection.selectedSpecialOfferSubtype ? createClientSection.selectedSpecialOfferSubtype.value : null
                                }
                            },
                            queryDate: formatShortDate(createClientSection.requestDate),
                            requestAttachments: createClientSection.requestAttachments,
                            policyAttachments: createClientSection.policyAttachments,
                            salePotentials: partyFleet,
                            ownedCars: ownedCars,
                            querySubject: requestSubjectSection.requestSubjects.map(function (requestSubject) {
                                var selectedOfferObject = requestSubjectSection.allOffers && requestSubject.selectedOffer
                                    ? requestSubjectSection.allOffers.find(function (offer) { return offer.id === requestSubject.selectedOffer; })
                                    : undefined;
                                var _a = selectedOfferObject ? selectedOfferObject.modelId.split('_') : [], modelCode = _a[0], modelYear = _a[1];
                                return {
                                    offerId: requestSubject.selectedOffer,
                                    pno12: selectedOfferObject.pno12,
                                    car: ModelDictionary.nameFor(modelCode),
                                    driver: selectedOfferObject.engine,
                                    version: selectedOfferObject.version,
                                    carCounter: Number(requestSubject.offerCarAmount),
                                    catalogPriceNet: selectedOfferObject.listPriceNet,
                                    catalogPriceGross: selectedOfferObject.listPriceGross,
                                    dealerShareMarginNet: requestSubject.selectedDealerMargin.value,
                                    vcpLevelNet: requestSubject.selectedFleetCoofinancing.value,
                                    finalPriceNet: requestSubject.percentagesValues
                                        ? requestSubject.percentagesValues.finalPriceNetto
                                        : 0,
                                    finalPriceGross: requestSubject.percentagesValues
                                        ? requestSubject.percentagesValues.finalPriceGross
                                        : 0,
                                    financingName: requestSubject.selectedFinancing,
                                    financingValue: fundingProviders(requestSubject)[requestSubject.selectedFinancing],
                                    modelYear: modelYear,
                                    offerNumber: selectedOfferObject.offerNo,
                                };
                            }),
                            additionalInfo: additionalInfosSection.additionalInfo,
                        };
                        setStatus('pending');
                        return [4 /*yield*/, specialOfferApi.saveNewSpecialOffer(specialOfferData)];
                    case 2:
                        saveNewSpecialOfferResponse = _a.sent();
                        if (saveNewSpecialOfferResponse) {
                            setStatus('success');
                            if (clientId) {
                                clientId_1 = searchParams.get('clientId');
                                window.location.href = "#/customer-service/".concat(clientId_1, "/");
                            }
                            if (searchParams.get('partyId')) {
                                partyId = searchParams.get('partyId');
                                window.location.href = "#/fleet-service/".concat(encodeURIComponent(partyId));
                            }
                        }
                        else {
                            setStatus('error');
                            setDialogMessage('Nie udało się zapisać oferty specjalnej');
                            setOpenConfirmDialog(true);
                            console.error('Bład zapisu oferty specjalnej');
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    function addNewRequestSubjectClick() {
        var id = uuid();
        requestSubjectSection.addNewRequestSubject(id);
        setTabValue(id);
    }
    function areLastRequestDataNotFilled() {
        var lastRequestSubject = requestSubjectSection.requestSubjects[requestSubjectSection.requestSubjects.length - 1];
        var shouldFinancingProviderExist = lastRequestSubject && lastRequestSubject.selectedFinancing !== 'CASH';
        if (!lastRequestSubject) {
            return false;
        }
        return (!lastRequestSubject.selectedOffer ||
            !lastRequestSubject.offerCarAmount ||
            !lastRequestSubject.selectedDealerMargin ||
            !lastRequestSubject.selectedFleetCoofinancing ||
            lastRequestSubject.percentagesValues === null ||
            (shouldFinancingProviderExist && !fundingProviders(lastRequestSubject)[lastRequestSubject.selectedFinancing]));
    }
    function isAddOfferButtonDisabled() {
        return (areLastRequestDataNotFilled() ||
            requestSubjectSection.availableOffers.length <= 0);
    }
    function isSaveDisabled() {
        function specialOfferTypeIsSelected() {
            var isTypeSelected = !!createClientSection.selectedSpecialOfferType;
            if (!isTypeSelected) {
                return false;
            }
            var shouldBeSubtypeSelected = createClientSection.selectedSpecialOfferType.subTypes.length > 0;
            if (!shouldBeSubtypeSelected) {
                return true;
            }
            else {
                return !!createClientSection.selectedSpecialOfferSubtype;
            }
        }
        return (!createClientSection.idSelectedParty ||
            !applicantSection.applicantData.organizationId ||
            !applicantSection.applicantData.organizationName ||
            !applicantSection.applicantData.applicantId ||
            !applicantSection.applicantData.applicantName ||
            createClientSection.requestAttachments.length === 0 ||
            areLastRequestDataNotFilled() ||
            !specialOfferTypeIsSelected() ||
            requestSubjectSection.requestSubjects.length === 0);
    }
    function handleOnConfirmDialog() {
        setOpenConfirmDialog(false);
        setDialogMessage('');
    }
    return (React.createElement("div", { className: "newSpecialOfferWrapper" },
        React.createElement(ViewSection, { title: "wnioskuj\u0105cy", dataTestid: "special-offer-applicant-section" },
            React.createElement(ApplicantData, { applicantSection: applicantSection })),
        React.createElement(ViewSection, { title: "zamawiaj\u0105cy", dataTestid: "special-offer-client-section" },
            React.createElement(ClientSection, { clientSection: createClientSection })),
        React.createElement(ViewSection, { title: "posiadana flota pojazd\u00F3w osobowych", dataTestid: "special-offer-sale-potential" },
            React.createElement(SalePotential, { mode: "CREATE", salePotentialSection: salePotentialSection })),
        React.createElement(ViewSection, { title: "dodaj przedmiot zapytania", dataTestid: "special-offer-request-subject" },
            React.createElement("button", { onClick: addNewRequestSubjectClick, className: "specialOfferPlusButton ".concat(isAddOfferButtonDisabled() ? 'disabled' : ''), disabled: isAddOfferButtonDisabled(), "data-testid": "add-rquest-subject-button" }, isAddOfferButtonDisabled() ? React.createElement(PlusDisabled, { className: "plus" }) : React.createElement(Plus, { className: "plus" })),
            React.createElement(NewRequestSubjectsSection, { tabValue: tabValue, onTabChange: onTabChange, idSelectedParty: createClientSection.idSelectedParty, requestSubjectSectiona: requestSubjectSection })),
        React.createElement(ViewSection, { title: "informacje dodatkowe", dataTestid: "special-offer-additional-infos" },
            React.createElement("div", { className: "newSpecialOfferComment" },
                React.createElement(CommentTextArea, { additionalInfosSection: additionalInfosSection }))),
        React.createElement(HorizontalLine, { color: "#6D6D6D" }),
        React.createElement("div", { className: "specialOfferSubmitButtonWrapper" },
            React.createElement("div", { className: "specialOfferSubmitButton" },
                React.createElement(Button, { variant: "primary", type: "button", onClick: onSaveSpecialOfferClick, disabled: isSaveDisabled() }, "wy\u015Blij zapytanie"))),
        React.createElement(InfoDialog, { open: openConfirmDialog, message: dialogMessage, onConfirm: handleOnConfirmDialog }),
        status === 'pending' ? React.createElement(Spinner, null) : null));
});
