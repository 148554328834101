import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { StockCarsListBodyRow } from './StockCarsListBodyRow';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
export function PaginatedStockCars(_a) {
    var stockCars = _a.stockCars, totalPages = _a.totalPages, changePageHandler = _a.changePageHandler, currentPage = _a.currentPage, handleSetAppError = _a.handleSetAppError;
    var _b = useState(), selectedCar = _b[0], setSelectedCar = _b[1];
    function onCarValueChange(value, event) {
        var preventEventTargetNames = ['IMG', 'path', 'svg'];
        var preventEventTargetClassNames = ['dialogOverlay'];
        if (preventEventTargetNames.includes(event.target.tagName) || preventEventTargetClassNames.includes(event.target.className)) {
            return;
        }
        setSelectedCar(value);
    }
    function onPageChange(event) {
        var selectedPage = event.selected;
        changePageHandler(selectedPage);
    }
    return (React.createElement(React.Fragment, null,
        stockCars ?
            stockCars.length === 1 ?
                React.createElement(StockCarsListBodyRow, { stockCar: stockCars[0], selectedCar: stockCars[0].commonOrderNumber, searchingMode: true, handleSetAppError: handleSetAppError })
                :
                    React.createElement(RadioGroup.Root, { value: selectedCar, onValueChange: function () { return onCarValueChange; } }, stockCars.map(function (stockCar) { return (React.createElement("div", { key: stockCar.commonOrderNumber, className: 'stockCarsListBodyRowComponent' },
                        React.createElement(StockCarsListBodyRow, { stockCar: stockCar, selectedCar: selectedCar, handleOnRowClick: function (event) { return onCarValueChange(stockCar.commonOrderNumber, event); }, handleSetAppError: handleSetAppError }))); }))
            : null,
        totalPages > 1 ? (React.createElement("div", { className: "stockCarsPagination", "data-testid": "pagination" },
            React.createElement(ReactPaginate, { breakLabel: "...", nextLabel: "Nast\u0119pna strona", onPageChange: onPageChange, pageRangeDisplayed: 5, pageCount: totalPages, previousLabel: "Poprzednia strona", renderOnZeroPageCount: null, pageLinkClassName: "page-link", previousClassName: "page-item", previousLinkClassName: "page-link-prev", nextClassName: "page-item", nextLinkClassName: "page-link-next", breakClassName: "page-item", breakLinkClassName: "page-link", containerClassName: "pagination", activeClassName: "active", activeLinkClassName: "active-link", forcePage: currentPage }))) : null));
}
